<template>
  <div class="summary" :class="{ 'summary--confirmed': currentStep === 6}">
    <!-- Event type -->
    <div v-if="shouldShowEventType" class="summary__data-container">
      <i v-if="isHealthcareSpecialty" class="summary__icon fas fa-notes-medical"></i>
      <i v-else class="summary__icon fas fa-toolbox"></i>
      <span>{{ eventType }}</span>
    </div>
    <!-- Provider -->
    <div v-if="shouldShowProviderData" class="summary__data-container" :class="{ 'summary__data-container--centered': shouldBeCentered}">
      <i v-if="isHealthcareSpecialty" class="summary__icon fas fa-user-md"></i>
      <i v-else class="summary__icon fas fa-user-tie"></i>
      <span>{{ provider }}</span>
    </div>
    <!-- Date -->
    <div v-if="shouldShowFinalInfo" class="summary__data-container">
      <i class="summary__icon fas fa-calendar-day"></i>
      <span>{{ formattedDate }}</span>
    </div>
    <!-- Time -->
    <div v-if="shouldShowFinalInfo" class="summary__data-container">
      <i class="summary__icon fas fa-clock"></i>
      <span>{{ time }}</span>
    </div>
    <!-- Timezone-->
    <div v-if="shouldShowTimezone" class="summary__data-container">
      <i class="summary__icon fas fa-globe-americas"></i>
      <span>{{ formattedTimezone }}</span>
    </div>
    <!-- Location -->
    <div v-if="shouldShowLocation" class="summary__data-container">
      <i class=" fas fa-map-marker-alt"></i>
      <span>{{ location }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { store } from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
  },
  props: {
    eventType: {
      type: String,
      default: ''
    },
    provider: {
      type: String,
      default: ''
    },
    date: {
      type: Date,
      default: null
    },
    time: {
      type: String,
      default: ''
    },
    timezone: {
      type: String,
      default: ''
    },
    location: {
      type: String,
      default: ''
    },
    isHealthcareSpecialty: {
      type: Boolean,
      required: true
    },
    isVirtualAppointment: {
      type: Boolean,
      default: false
    },
    shouldShowSummaryProviderName: {
      type: Boolean,
      required: true
    },
    currentStep: {
      type: Number,
      required: true
    },
    shouldBeCentered: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    lang () : string {
      return store.state.lang
    },
    formattedDate (): string {
      // If there is no date, return an empty string
      if (!this.date) return ''
      // Else return the date in the format: Tuesday, August 29, 2023
      const formatted = this.date.toLocaleString(this.lang, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
      return formatted.charAt(0).toUpperCase() + formatted.slice(1)
    },
    formattedTimezone (): string {
      // Formatter to get the abbreviation of the timezone
      const formatter = new Intl.DateTimeFormat(this.lang, {
        timeZone: this.timezone,
        timeZoneName: 'short'
      })
      // This abbreviation is the short name of the timezone, for example: GMT-3
      const abbrev = formatter.formatToParts(this.date).find((part) => part.type === 'timeZoneName')?.value || ''
      return `${this.timezone.replaceAll('_', ' ')} (${abbrev})`
    },
    shouldShowEventType (): boolean {
      // The event type should be shown only if we are in the confirm view
      const isOnRequiredSteps = this.currentStep === 6
      return isOnRequiredSteps
    },
    shouldShowProviderData () : boolean {
      return Boolean(this.provider) && this.shouldShowSummaryProviderName
    },
    shouldShowFinalInfo (): boolean {
      // The final info should be shown only if we are in the form or in the confirm view.
      // The date, time, timezone and location are final info
      const isOnRequiredSteps = this.currentStep >= 5
      return isOnRequiredSteps
    },
    shouldShowTimezone (): boolean {
      // The location should be shown only if it is not a virtual appointment, have a location and we are in the required step/view
      return Boolean(this.timezone) && this.shouldShowFinalInfo
    },
    shouldShowLocation (): boolean {
      // The location should be shown only if it is not a virtual appointment, have a location and we are in the required step/view
      return !this.isVirtualAppointment && Boolean(this.location) && this.shouldShowFinalInfo
    }
  }
})
</script>
