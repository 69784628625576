export enum ErrorCodes {
  HASHTAH_NOT_FOUND = 1,
  SERVER_ERROR = 2,
  SLOTS_NOT_FOUND = 3,
  FAILED_DEPENDENCY = 424,
  SLOT_ALREADY_TAKEN = 455,
  EVENT_NOT_INSERTED = 471,
  CONTACT_NOT_CREATED = 491,
  CONTACT_NOT_FOUND = 492,
  CONTACT_NOT_UPDATED = 493,
  CONTACT_BLOCKED = 494,
  GATEWAY_TIMEOUT = 504
}
