<template>
  <div v-for="(provider, index) in providers" :key="provider.id" @click="$emit('clickProviderButton', provider)" class="button flex no-padding">
    <div class="provider-info">
      <!-- Image and provider name -->
      <div class="provider-info__header" :class="{'header-with-image-preselected': typeof provider.image != 'undefined' && provider.image != '' && providerPreSelected === provider}">
        <!-- Photo -->
        <img v-if="typeof provider.image != 'undefined' && provider.image != ''" class="provider-image" :src="provider.image" />
        <!-- Name -->
        <div class="provider-name" :class="{'provider-name-preselected': providerPreSelected == provider}">
          {{ provider.name }}
        </div>
      </div>
      <!-- Description -->
      <div v-if="providerPreSelected == provider" class="provider-description" :class="{'provider-description-hidden': !isShowingFullDescription[index]}">
        <span v-if="!isShowingFullDescription[index]">{{provider.description?.length > 60 ? provider.description?.slice(0,52)+ '...' : provider.description}}</span>
        <span v-else>{{provider.description}}</span>
      </div>
      <!-- Toggle full description buttons -->
      <div v-if="provider.description?.length > 60 && providerPreSelected == provider" class="button__description-control-buttons">
        <span v-if="!isShowingFullDescription[index] " @click="updateProviderDescription(index, true)"><i class="fa fa-plus-circle"></i></span>
        <span v-else @click="updateProviderDescription(index, false)"><i class="fa fa-minus-circle"></i></span>
      </div>
    </div>
    <!-- Confirm figure -->
    <div v-if="providerPreSelected == provider" class="button__confirm-arrow"  :class="confirmProviderExtraClasses">
      <IconArrowCircle class="button__confirm-arrow-svg" direction="right"/>
    </div>
  </div>
</template>

<script>
import IconArrowCircle from '@/components/icons/IconArrowCircle.vue'
import userColorsMixin from '@/mixins/userColorsMixin.ts'
import { store } from '@/store'
export default {
  components: {
    IconArrowCircle
  },
  props: {
    providers: {
      type: Array,
      required: true
    },
    providerPreSelected: {
      type: Object,
      default () {
        return { }
      }
    },
    currentStep: {
      type: Number
    }
  },
  data () {
    return {
      isShowingFullDescription: []
    }
  },
  computed: {
    confirmProviderExtraClasses () {
      return {
        'button__confirm-arrow--contrasted': this.useBackgroundAndTextColorsForConfirmFigure || this.useHoverSelectedBackgroundAndTextColorsForConfirmFigure
      }
    }
  },
  watch: {
    currentStep (currentStep) {
      // Every time we are in Doctors step
      if (currentStep === 2) {
        // Close descriptions opened
        this.isShowingFullDescription = {}
        this.$emit('resetProviderPreSelected')
      }
    }
  },
  methods: {
    updateProviderDescription (index, value) {
      event.stopPropagation()
      this.isShowingFullDescription[index] = value
    }
  },
  emits: ['clickProviderButton', 'resetProviderPreSelected'],
  mixins: [userColorsMixin]
}
</script>

<style lang="scss" scoped>
.provider-info {
  padding: 12px;
  width: 100%;
}

.provider-info__header {
  display: flex;
  column-gap: 14px;
  align-items: center;
}

.header-with-image-preselected {
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
}

.provider-image {
    min-width: 80px;
    max-width: 80px;
    height: 80px;
    border-radius: 87px;
    object-fit: cover;
  }

.provider-name {
  margin: auto;
}

.provider-name-preselected {
  font-size: 1.1rem;
}

.provider-description {
  margin: 15px 0;
  font-size: 1rem;
  text-align: left;
}

.provider-description-hidden {
  max-height: 70px;
  overflow: hidden;
}

</style>
