// https://flagpedia.net/index
// https://www.codigosinternacionales.com/ladas-de-paises/
// https://en.wikipedia.org/wiki/List_of_mobile_telephone_prefixes_by_country
export default [
  { key: 'us', code: '+1', name: 'USA', validLengths: [10] },
  { key: 'ca', code: '+1', name: 'Canada', validLengths: [10] },
  { key: 'mx', code: '+52', name: 'México', validLengths: [10] },
  { key: 'ar', code: '+54', name: 'Argentina', validLengths: [10] },
  { key: 'br', code: '+55', name: 'Brasil', validLengths: [11] },
  { key: 'cl', code: '+56', name: 'Chile', validLengths: [9] },
  { key: 'co', code: '+57', name: 'Colombia', validLengths: [10] },
  { key: 'cr', code: '+506', name: 'Costa Rica', validLengths: [8] },
  { key: 'de', code: '+49', name: 'Deutschland', validLengths: [10, 11] },
  { key: 'ec', code: '+593', name: 'Ecuador', validLengths: [9] },
  { key: 'es', code: '+34', name: 'España', validLengths: [9] },
  { key: 'fr', code: '+33', name: 'France', validLengths: [9] },
  { key: 'gt', code: '+502', name: 'Guatemala', validLengths: [8] },
  { key: 'hn', code: '+504', name: 'Honduras', validLengths: [8] },
  { key: 'nl', code: '+31', name: 'Nederland', validLengths: [9] },
  { key: 'pa', code: '+507', name: 'Panama', validLengths: [8] },
  { key: 'pe', code: '+51', name: 'Perú', validLengths: [9] },
  { key: 'ch', code: '+41', name: 'Switzerland', validLengths: [9] },
  { key: 'gb', code: '+44', name: 'United Kingdom', validLengths: [10] },
  { key: 'uy', code: '+598', name: 'Uruguay', validLengths: [8] }
]
