<template>
  <transition name="slide-down-fade">
    <div v-show="show" class="banner">
      <!-- Message -->
      <div>
        {{ message }}
      </div>
      <!-- Close -->
      <div @click="close" class="close-button">
        <span class="fas fa-times"></span>
      </div>
    </div>
  </transition>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    show: {
      type: Boolean,
      required: true
    },
    message: {
      type: String,
      default: ''
    }
  },
  emits: ['close'],
  methods: {
    close () {
      this.$emit('close')
    }
  }
})
</script>

<style>
@import "./../../styles/transitions/slide-down-fade.scss";

.banner {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px 32px;
  width: 100%;
  padding: 0.75rem 1.5rem;
  background-color: var(--dark-blue);
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  color: var(--white);
}

.close-button {
  cursor: pointer;
  color: var(--white);
}
</style>
