<template>
  <div class="timezone-selector">
    <span @click="openSelector = !openSelector"><i class="fas fa-globe-americas hidden-mobile"></i> {{ formatTimezone(timezone) }} &#9662;</span>
    <ul v-if="openSelector">
      <li><input class="search" type="text" :placeholder="$t('global.search')" v-model.trim="inputSearch"></li>
      <li v-for="it in timezones" :key="it" @click="select(it)" v-show="!inputSearch || it.match(new RegExp(inputSearch, 'gi'))">{{ formatTimezone(it) }} ({{ new Date().toLocaleString(lang, { timeZone: it, hour: '2-digit', minute: '2-digit', hour12: true }) }})</li>
    </ul>
  </div>
  <!-- Space for the user to better interact with the timezone selector -->
  <div v-if="openSelector" class="extra-space-timezone-selector"></div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component'
import { store } from '@/store'
import UtilService from '@/services/UtilService'

export default class TimezoneSelector extends Vue {
  timezones = UtilService.getTimezones()
  openSelector = false
  inputSearch = ''

  get lang () {
    return store.state.lang
  }

  get timezone () {
    return store.state.timezone
  }

  formatTimezone (original: string): string {
    const auxDate = new Date()
    const formatter = new Intl.DateTimeFormat(this.lang, {
      timeZone: original,
      timeZoneName: 'short'
    })
    const abbrev = formatter.formatToParts(auxDate).find((part) => part.type === 'timeZoneName')?.value || ''
    return `${original.replaceAll('_', ' ')} (${abbrev})`
  }

  select (timezone: string) {
    if (this.timezone !== timezone) {
      store.commit('changeTimezone', timezone)
      this.$emit('select', timezone)
    }
    this.openSelector = false
  }
}
</script>

<style lang="scss" scoped>
.extra-space-timezone-selector {
  // The same height that timezone Selector in mobile
  @media (max-width: 700px) {
    height: 155px;
  }
}
</style>
