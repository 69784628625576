<template>
  <TopBanner :show="shouldShowGoogleAddress" @close="shouldShowGoogleAddress = false" :message="truncatedAccountName + ' - ' + googleReservationAddress"></TopBanner>
 <!-- <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" /> -->
  <Header
    v-if="locationConfig.location.id"
    :logo="locationConfig.location.image"
    :title="currentStep <= STEPS.APPOINTMENT || currentStep === STEPS.CONFIRM ? locationConfig.location.name : appointmentTypeSelected.name"
    :subtitle="locationConfig.location.name"
    :currentStep="currentStep"
  >
    <!-- If is desktop and doesn't have logo, we will center the summary  -->
    <Summary
      v-if="isDesktop && currentStep > STEPS.DOCTOR && currentStep < STEPS.FORM"
      :event-type="appointmentTypeSelected.name"
      :provider="summaryProviderName"
      :date="dateSelected"
      :time="summaryTime"
      :timezone="timezoneSelected"
      :location="summaryAddress"
      :is-healthcare-specialty="isHealthcareSpecialty"
      :is-virtual-appointment="appointmentTypeSelected.isVirtual"
      :shouldShowSummaryProviderName="shouldShowSummaryProviderName"
      :currentStep="currentStep"
      :shouldBeCentered="!locationConfig.location.image"
    />
  </Header>

  <div class="container wizard-page" :class="{'overflow-hidden':  currentStep > STEPS.SELF_SCHEDULING_WARNING && currentStep < STEPS.FORM, 'overflow-auto': currentStep === STEPS.CONFIRM}">
    <div class="initialization" :class="{ done: initializationDone }">
      <img class="light" src="https://s3.amazonaws.com/assets.nimblr.ai/brand/logos/FullColor.png">
      <img class="dark" src="https://s3.amazonaws.com/assets.nimblr.ai/brand/logos/WhiteOrangeColor.png">
      <h5 v-if="!stateSelectionActive">{{ $t('global.loading_message') }}</h5>
      <select class="state-input" v-if="stateSelectionActive" @change="onStateSelected($event.target.value)">
        <option>{{ $t('wizard.choose_state') }}</option>
        <option v-for="it in states" :key="it.id" :value="it.id">{{ it.name }}</option>
      </select>
    </div>

    <div class="back-button" v-if="canGoBack" @click="goToStep(currentStep - 1)">
      <IconArrowCircle direction="left"/>
    </div>

    <!-- STEP -1: Inbound negative conditional -->
    <transition :name="transitions['-1']">
      <section class="step step--compacted" v-show="currentStep === STEPS.INBOUND_NEGATIVE_CONDITIONAL">
        <div class="negative-conditional-message">
          <div v-for="(paragraph,index) in getFormattedSelfSchedulingText('inbound_negative_conditional', { phone: locationConfig.location.phone }).split('\n') " :key="index">{{ paragraph }}<br></div>
        </div>
      </section>
    </transition>

    <!-- STEP 0: Self scheduling warning -->
    <transition :name="transitions['0']">
      <section class="step step--compacted" v-show="currentStep === STEPS.SELF_SCHEDULING_WARNING">
        <div class="starting-message">
          <div v-for="(paragraph,index) in selfSchedulingWarning.split('\n')" :key="index">{{ paragraph }}<br></div>
        </div>
        <div v-if="locationConfig.config.isConditionalQuestionEnabled" class="yes-no-options">
          <button @click="goToStep(STEPS.INBOUND_NEGATIVE_CONDITIONAL)" class="button">{{ $t('global.no') }}</button>
          <button @click="goToStep(STEPS.APPOINTMENT)" class="button">{{ $t('global.yes') }}</button>
        </div>
        <div v-else class="only-one-option">
          <button @click="goToStep(STEPS.APPOINTMENT)" class="button">{{ $t('global.continue') }}</button>
        </div>
      </section>
    </transition>

    <!-- STEP 1: APPOINTMENT TYPE SELECTION -->
    <transition :name="transitions['1']">
      <section class="step step-1" v-show="(isMobile && currentStep === STEPS.APPOINTMENT) || (isDesktop && (currentStep === STEPS.APPOINTMENT || currentStep === STEPS.DOCTOR))">
        <!-- Having appointmentTypes to show-->
        <template v-if="appointmentTypes && appointmentTypes.length">
          <div class="instructions">{{ $t('wizard.choose_appointment_type') }}</div>

          <div class="user-options">
            <AppointmentTypesList
              :appointmentTypes="appointmentTypes"
              :appointmentTypePreSelected="appointmentTypePreSelected"
              :appointmentTypeSelected="appointmentTypeSelected"
              @clickAppointmentTypeButton="onClickAppointmentTypeButton"
            ></AppointmentTypesList>
          </div>
        </template>
        <!-- No having appointmentTypes to show-->
        <template v-else>
          <!-- By state -->
          <div v-if="stateSelected" class="instructions">{{ $t('wizard.no_available_appointment_types_by_state') }}</div>
          <!-- No by state -->
          <div v-else class="instructions">{{ $t('wizard.no_available_appointment_types')  }}</div>
        </template>
      </section>
    </transition>

    <!-- STEP 2: DOCTOR SELECTION -->
    <transition :name="transitions['2']">
      <section class="step step-2" v-show="currentStep === STEPS.DOCTOR && shouldAskProvider">
          <div class="instructions">{{ $t('wizard.choose_doctor') }}</div>
        <!-- Having providers to show-->
        <template v-if="providers && providers.length">
          <div class="user-options">
            <!-- Any provider button -->
            <button v-if="shouldShowAnyProviderButton" @click="onClickAnyProviderButton" type="button" class="button">{{ $t('wizard.any_provider')}}</button>
            <!-- Providers list -->
            <ProvidersList
            :providers="providers"
            :providerPreSelected="providerPreSelected"
            :currentStep="currentStep"
            @clickProviderButton="onClickProviderButton"
            @resetProviderPreSelected="providerPreSelected = {}"
            > </ProvidersList>
          </div>
        </template>
        <!-- No having providers to show-->
        <RetryableErrorView
          v-else
          :titleText="stateSelected ? $t('wizard.no_available_providers_by_state') : $t('wizard.no_available_providers')"
          :withoutButton="isDesktop"
          :buttonText="$t('wizard.go_back')"
          :isHalfScreen="isDesktop"
          @buttonClicked="goToStep(STEPS.APPOINTMENT)"
        />
      </section>
    </transition>

    <!-- STEP 3: DATE SELECTION -->
    <transition :name="transitions['3']">
      <section v-show="(isMobile && currentStep === STEPS.DATE) || (isDesktop && (currentStep === STEPS.DATE || currentStep === STEPS.TIME))" class="step step-3" :class="{'step--compacted': !thereIsAvailableDates}">
        <div class="instructions warning" v-if="locationConfig.config.showTimeoutWarning && loading">{{ $t('wizard.warning_message') }}</div>
        <div class="instructions" v-if="(locationConfig.config.showTimeoutWarning && !loading) || !locationConfig.config.showTimeoutWarning ">{{ $t('wizard.choose_date') }}</div>
        <div v-if="thereIsAvailableDates">
          <TimezoneSelector v-if="isDesktop"/>
          <Calendar
          ref="calendar"
          @select="onDateSelected"
          @updateLoading="updateLoading"
          @updateLoadingSlots="updateLoadingSlots"
          @noAvailableDates="thereIsAvailableDates = false"
          @hideDaySlots="goToStep(STEPS.DATE)"
          :location="locationConfig.location.id"
          :userId="locationConfig.location.userId"
          :appointmentType="appointmentTypeSelected.id"
          :provider="(providerSelected && providerSelected.hasOwnProperty('id')) ? String(providerSelected.id) : undefined"
          :providerIds="providers && providers.map(provider=> provider.id) || []"
          :timezone="timezoneSelected"
          :shouldAskProvider="shouldAskProvider"
          :isAnyProviderSelected="isAnyProviderSelected"
          :isOptimizeProvidersOfferingEnabled="locationConfig.config.isOptimizeProvidersOfferingEnabled"
          :currentStep="currentStep"
          :autoSelectDate="(isMobile && currentStep === STEPS.TIME) || (isDesktop && (currentStep === STEPS.DATE || currentStep === STEPS.TIME))"
          :availableDatesOnMemory="locationConfig.config.availableDatesOnMemory"
          :googleAnalyticsId="locationConfig.config.idGA"
          :funnelId="String(locationConfig.config.funnelId)"
          :providerName="providerSelected.name"
          :useIframe="useIframe"
          :stateSelected="stateSelected"/>
        </div>
        <!-- No having available dates to show-->
        <RetryableErrorView
          v-else
          :titleText="$t('errors.no_available_dates_title')"
          :bodyText="$t('errors.no_available_dates_body')"
          :buttonText="$t('wizard.go_back')"
          @buttonClicked="goToStep(currentStep - 1)"
        />
      </section>
    </transition>

    <!-- STEP 4: SLOT (TIME) SELECTION -->
    <transition :name="transitions['4']">
      <section class="step step-4" v-show="currentStep === STEPS.TIME">
        <div>
          <div class="instructions">{{ $t('wizard.choose_time') }}</div>
          <!-- Loading slots -->
          <div class="loading-slots" v-if="loadingSlotsCalendar"><IconLoading :dark="true" /></div>
          <!-- Info if there are slots -->
          <div v-else-if="slots.length > 0">
            <div v-if="isDesktop" class="selected-date">
              <h3 class="date">{{ dateSelected.toLocaleString(lang, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }}</h3>
            </div>
            <TimezoneSelector v-if="isMobile"/>
          </div>
        </div>

        <!-- Message if there are no slots -->
        <RetryableErrorView
          v-if="!loadingSlotsCalendar && !slots.length"
          :titleText="$t('errors.slots_not_found')"
          :withoutButton="isDesktop"
          :buttonText="$t('wizard.go_back')"
          :isHalfScreen="isDesktop"
          @buttonClicked="goToStep(STEPS.DATE)"
        />

        <!-- Slots list -->
        <div class="user-options" v-if="!loadingSlots && !loadingSlotsCalendar">
          <div v-for="it in slots" :key="it.calendarId" @click="onClickSlotButton(it)" class="button">
            <div> {{ it.start }}</div>
          </div>
        </div>
        <MockContent :rows="5" :cols="1" rowHeight="62px" v-if="loadingSlots"/>
      </section>
    </transition>

    <!-- STEP 5: FORM -->
    <transition :name="transitions['5']">
      <section class="step step-5" v-if="currentStep === STEPS.FORM">
        <div class="instructions">{{ $t('wizard.complete_your_schedule') }}</div>
        <div class="user-form">
          <Summary
            :class="{'summary--form': isDesktop}"
            :event-type="appointmentTypeSelected.name"
            :provider="summaryProviderName"
            :date="dateSelected"
            :time="summaryTime"
            :timezone="timezoneSelected"
            :location="summaryAddress"
            :is-healthcare-specialty="isHealthcareSpecialty"
            :is-virtual-appointment="appointmentTypeSelected.isVirtual"
            :shouldShowSummaryProviderName="shouldShowSummaryProviderName"
            :currentStep="currentStep"
          />
          <template v-if="isReferrals">
            <!-- Referrer email -->
            <div class="field w50" :class="{ error: formErrors.includes('input-referrer-email') }">
              <label for="input-referrer-email">{{ $t('wizard.form_field_referrer_email') }} *</label>
              <input type="email" id="input-referrer-email" v-model.trim="form.referrer.email" :placeholder="$t('wizard.form_field_referrer_email')" maxlength="250">
            </div>
            <!-- Referrer Address -->
            <InputText name="referrerAddress" />

            <!-- Referrer FirstName -->
            <InputText name="referrerFirstName" :acceptJustLetters="true" maxLength="250" />
            <!-- Referrer LastName -->
            <InputText name="referrerLastName" :acceptJustLetters="true" maxLength="250" />

            <!-- Label to specify that the next information is Patient's information -->
            <span class="section-title">{{ $t('wizard.referrals_patient_information')}}</span>
          </template>

          <!-- FirstName -->
          <InputText name="firstName" :acceptJustLetters="true" maxLength="250" :isMandatory="true"/>

          <!-- LastName -->
          <InputText name="lastName" :acceptJustLetters="true" maxLength="250" :isMandatory="true"/>

          <div class="field w50 prefixed" :class="{ error: formErrors.includes('input-phone') }">
            <label for="input-phone">{{ $t('wizard.form_field_cellphone') }} *</label>
            <input v-if="countryCodeSelected.code == '+1'" type="text" id="input-phone" v-mask="['(###) ###-####']" v-model.trim="form.phoneNumber" :placeholder="$t('wizard.form_field_cellphone')" maxlength="250">
            <input v-else type="text" id="input-phone" v-model.trim="form.phoneNumber" :placeholder="$t('wizard.form_field_cellphone')" maxlength="250">
            <div class="prefix">
              <div class="selected" @click="countryCodesActive = !countryCodesActive">
                <img :src="`https://flagcdn.com/${countryCodeSelected.key}.svg`" width="24"><span>{{ countryCodeSelected.code }}</span>
              </div>
              <div class="list-options" v-show="countryCodesActive">
                <div class="code-item" v-for="it in countryCodes" :key="it.key" :title="it.name" @click="selectCountryCode(it)">
                  <img :src="`https://flagcdn.com/${it.key}.svg`" width="24"><span>{{ it.code }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Email -->
          <div class="field w50" :class="{ error: formErrors.includes('input-email') }">
            <label for="input-email">{{ $t('wizard.form_field_email') + (isReferrals && !shouldRegisterPaymentMethod ? ' ': ' *')}}</label>
            <input
              type="email"
              id="input-email"
              v-model.trim="form.email"
              :placeholder="isReferrals && !shouldRegisterPaymentMethod ? $t('wizard.form_field_placeholder_optional_recommended'): $t('wizard.form_field_email')"
              maxlength="250">
          </div>

          <!-- BirthDay -->
          <div v-if="locationConfig.config.askDateOfBirth || isReferrals" class="field birthday">
            <label for="input-birthday-day">{{ $t('wizard.form_field_birthday') }} *</label>
            <div>
              <div v-for="datePart in birthdateInputs" :key="datePart">
                <label :for="`input-birthday-${datePart}`">{{ $t(`wizard.form_field_birthday_${datePart}`) }}</label>
                <input
                  v-model="form.birthdate[datePart]"
                  @keypress="acceptJustDigits"
                  :id="`input-birthday-${datePart}`"
                  :class="{ error: formErrors.includes(`input-birthday-${datePart}`) }"
                  :placeholder="BIRTHDATE_INPUT[datePart].placeholder"
                  :maxlength="Number(BIRTHDATE_INPUT[datePart].maxlength)"
                  type="text"
                >
              </div>
            </div>
          </div>

          <!-- Legal sex -->
          <InputSelect v-if="locationConfig.config.askLegalSex || isReferrals"
            name="legalSex"
            :is-single-in-line="true"
            :optionsList="{
              'M': $t('wizard.form_field_legal_sex_male'),
              'F': $t('wizard.form_field_legal_sex_female'),
              'O': $t('wizard.form_field_legal_sex_other')
            }"
            :isMandatory="true"
          />

          <!-- Address form -->
          <template v-if="locationConfig.config.askAddress || isReferrals">
            <!-- Address -->
            <InputText name="address" :isMandatory="true"/>
            <!-- Zip code -->
            <InputText name="zipCode" :acceptJustNumbers="true" maxLength="5"  :isMandatory="true"/>
            <!-- State -->
            <InputSelect name="state" :optionsList="availableStates" :warningMessage="$t('wizard.form_field_state_warning')" :validationToShowWarningMessage="areCustomServiceStates" :isMandatory="true"/>
            <!-- City -->
            <InputText name="city" :isMandatory="true"/>
          </template>

          <!-- New patients form -->
          <div v-if="shouldAskNewPatientQuestions" class="questions-group">
            <!-- Zip code -->
            <InputText v-if="!locationConfig.config.askAddress && !isReferrals" name="zipCode" :acceptJustNumbers="true" maxLength="5" :isMandatory="true"/>
            <!-- State -->
            <InputSelect
              v-if="!locationConfig.config.askAddress && !isReferrals"
              name="state"
              :optionsList="availableStates"
              :warningMessage="$t('wizard.form_field_state_warning')"
              :validationToShowWarningMessage="areCustomServiceStates"
              :isMandatory="true"
            />
          </div>

          <!-- Insurance Warning -->
          <p v-if="shouldShowInsuranceWarning" class="insurance-warning">
          {{ insuranceWarning }}
          </p>

          <!-- Extended Insurance form -->
          <template v-if="locationConfig.config.goingToGatherExtendedInsuranceInformation || isReferrals">
            <!-- Company -->
            <InputText name="insuranceCompany" placeholder="e.g. Aetna" :isMandatory="locationConfig.config.mandatoryExtendedInsuranceQuestions?.includes('InsuranceCompany') || isReferrals"/>
            <!-- Group Number -->
            <InputText name="insuranceGroupNumber" placeholder="e.g. 12345678" :isMandatory="locationConfig.config.mandatoryExtendedInsuranceQuestions?.includes('GroupId') || isReferrals"/>
            <!-- Id Number -->
            <InputText name="insuranceIdNumber" placeholder="e.g. ABC456789" :isMandatory="locationConfig.config.mandatoryExtendedInsuranceQuestions?.includes('MemberId') || isReferrals"/>
            <!-- Plan Type -->
            <InputSelect
              v-if ="Object.keys(extendedInsurancePlanTypesToSelect).length"
              name="insurancePlanType"
              :options-list="extendedInsurancePlanTypesToSelect"
              :isMandatory="locationConfig.config.mandatoryExtendedInsuranceQuestions?.includes('PlanType') || isReferrals"/>
          </template>

          <!-- Selector to decide if gather for Insurance card or SSN in excluding mode-->
          <div v-if="shouldUseInsuranceWorkflow && locationConfig.config.insuranceWorkflow == 'excluding' && !isReferrals" class="field" style="width: 186%;">
            <!-- Select Insurance card -->
            <div class="label-between" style="display:inline-block; height: 58px; ">
              <label class="label" style="margin-top: 7px;">
                <label class="container-checked"><p style="font-size: 16px;">{{ locationConfig.config.insuranceCardLabel ? locationConfig.config.insuranceCardLabel : $t('wizard.form_field_insurance') }}</p>
                  <input type="radio" name="radio" style="width: 0%; min-height: 19px;" value="insurance" v-model="chooseSecure"  >
                  <span class="checkmark" :class="{ error: formErrors.includes(`input-radio`)}" ></span>
                </label>
              </label>
            </div>
            <!-- Select Insurance card -->
            <p v-if="chooseSecure == 'insurance'" style="margin-top: 10px !important" class="label-image">{{ $t('wizard.form_field_image') }}</p>
            <div class="image-choose">
              <ImagePicker
                v-if="chooseSecure == 'insurance'"
                :type="'frontImage'"
                :title="$t('wizard.form_field_image_one') + ' *'"
                :formErrors="formErrors"
                :url="url"
                @updateForm="updateForm"
                @updateAddFormErrors="updateAddFormErrors"
                @updateDelFormErrors="updateDelFormErrors"/>

              <ImagePicker
                class="image-left"
                v-if="chooseSecure == 'insurance'"
                :type="'backImage'"
                :title="$t('wizard.form_field_image_two') + ' *'"
                :formErrors="formErrors"
                :url="url"
                @updateForm="updateForm"
                @updateAddFormErrors="updateAddFormErrors"
                @updateDelFormErrors="updateDelFormErrors"/>
            </div>
            <!-- Select SNN -->
            <div class="label-between" style="display:inline-block; height: 38px; margin-top: -11px; margin-bottom: 40px;">
              <label class="label" style="margin-top: 10px;">
                <label class="container-checked"><p style="font-size: 16px; text-align: justify; margin-top: 24px;">{{ locationConfig.config.ssnLabel ? locationConfig.config.ssnLabel : $t('wizard.form_field_ssn') }}</p>
                  <input type="radio" name="radio" style="width: 0%; min-height: 19px;" value="ssn" v-model="chooseSecure"   >
                  <span class="checkmark" :class="{ error: formErrors.includes(`input-radio`)}"></span>
                </label>
              </label>
            </div>
          </div>

          <!-- Insurance Card -->
          <template v-if="shouldAskInsuranceCard || isReferrals">
            <!-- Label -->
            <p class="label-image">
              {{ locationConfig.config.insuranceCardLabel ? locationConfig.config.insuranceCardLabel : $t('wizard.form_field_insurance') }}
            </p>
            <!-- Front side -->
            <ImagePicker
              :type="'frontImage'"
              :title="$t('wizard.form_field_image_one') + ((locationConfig.config.insuranceCardMandatory || locationConfig.config.insuranceWorkflow == 'mandatory') && !isReferrals ? ' *' : '')"
              :formErrors="formErrors"
              :url="url"
              @updateForm="updateForm"
              @updateAddFormErrors="updateAddFormErrors"
              @updateDelFormErrors="updateDelFormErrors"/>
            <!-- Back side -->
            <ImagePicker
              :type="'backImage'"
              :title="$t('wizard.form_field_image_two') + ((locationConfig.config.insuranceCardMandatory || locationConfig.config.insuranceWorkflow == 'mandatory') && !isReferrals ? ' *' : '')"
              :formErrors="formErrors"
              :url="url"
              @updateForm="updateForm"
              @updateAddFormErrors="updateAddFormErrors"
              @updateDelFormErrors="updateDelFormErrors"/>
          </template>

          <!-- SNN -->
          <template v-if="shouldAskSSN">
            <div class="field w50" style="margin-top:3px" :class="{ error: formErrors.includes('input-ssn') }">
              <label for="input-ssn">{{ snnFinalLabel }}</label>
              <input type="text" id="input-ssn" v-model.trim="form.ssn" :placeholder="'XXX-XX-XXXX'" v-mask="['###-##-####']" maxlength="250">
            </div>
            <!-- Divide with extended insurance form -->
            <div class="field w50"></div>
          </template>

          <!-- Extra questions -->
          <template v-if="!isReferrals">
            <div class="field" v-for="(additionalQuestion, index) in locationConfig.location.additionalQuestions" :key="index" :class="{ error: formErrors.includes('input-extra-question'+index) }">
              <label :for="'input-extra-question'+index">{{ additionalQuestion + (locationConfig.config.additionalQuestionsAsMandatoryQuestions ? ' *' : '') }}</label>
              <input
                type="text"
                :id="'input-extra-question'+index"
                v-model.trim="form.extraQuestions[index]"
                :placeholder="locationConfig.config.additionalQuestionsAsMandatoryQuestions ? additionalQuestion : $t('wizard.form_field_placeholder_optional')"
                :maxlength="500">
            </div>
          </template>

          <!-- Visit Reason -->
          <InputTextArea
            v-if="locationConfig.config.showVisitReasonQuestion"
            name="visitReason"
            :label="(locationConfig.config.customVisitReasonLabel && locationConfig.config.customVisitReasonLabel[$i18n.locale]) || $t('wizard.form_field_visit_reason')"
            :isMandatory="locationConfig.config.visitReasonAsMandatoryQuestion">
          </InputTextArea>

          <!-- Terms and Conditions -->
          <div class="field terms-and-conditions" style="margin:0"
            v-if="locationConfig.config.askTermsAndConditions && locationConfig.config.termsAndConditionsLink && !isReferrals">
            <div class="label-between" style="display:inline-block; height: 58px; width: 100%;">
              <label class="label" style="margin-top: 7px;">
                <label class="container-checked">
                  <p style="font-size: 16px;">{{ $t('wizard.form_label_terms_and_conditions_message') }}
                    <a :href="locationConfig.config.termsAndConditionsLink" target="_blank">
                    {{ appointmentTypeSelected.isVirtual ? $t("wizard.form_label_terms_and_conditions_telehealth_link") : $t("wizard.form_label_terms_and_conditions_link") }}
                    </a>
                    </p>
                  <input type="checkbox" style="width: 0%; min-height: 19px;" v-model="theTermsAndConditionsWereAccepted"  class="checkmark">
                  <span class="checkmark" :class="{ error: formErrors.includes(`input-terms-and-conditions`)}" ></span>
                </label>
              </label>
            </div>
          </div>

          <!-- Consent to text -->
          <div v-if="shouldAskConsentToText" class="field consent-to-text-field" id="input-consent-to-text" :class="{ error: formErrors.includes(`input-consent-to-text`)}">
            <label>{{ $t('wizard.form_label_consent_to_text')}} *</label>
              <label class="input-radio__label">
                <input type="radio" value="true" v-model="form.consentToText" class="input-radio__input">
                {{ $t('global.yes') }}
              </label>
              <label class="input-radio__label">
                <input type="radio" value="false" v-model="form.consentToText" class="input-radio__input">
                {{ $t('global.no') }}
              </label>
          </div>

          <div style="width: 100%">
            <button @click="onFormComplete" :disabled="!scheduledButtonShouldBeEnable || savingAppointment" class="button" :class="{ 'button--loading': savingAppointment}">
              <span v-if="!savingAppointment">{{ useCustomText("formSubmitButton", $t('wizard.form_submit_button')) }}</span>
              <IconLoading v-else/>
            </button>
          </div>
        </div>
      </section>
    </transition>

    <!-- STEP 6: CONFIRMATION -->
    <transition :name="transitions['6']">
      <section class="step step--compacted step-6" v-if="currentStep === 6">

        <h2 class="emphasis-text text-center">{{ completeSchedulingHeader }}</h2>

        <Summary
          :event-type="appointmentTypeSelected.name"
          :provider="summaryProviderName"
          :date="dateSelected"
          :time="summaryTime"
          :timezone="timezoneSelected"
          :location="summaryAddress"
          :is-healthcare-specialty="isHealthcareSpecialty"
          :is-virtual-appointment="appointmentTypeSelected.isVirtual"
          :shouldShowSummaryProviderName="shouldShowSummaryProviderName"
          :currentStep="currentStep"
        />

        <!-- Additional message -->

        <!-- Process payment workflow -->
        <template v-if="shouldProcessPayment">
          <!-- With auto cancellation lead time -->
          <template v-if="hasAutoCancellationLeadTime">
            <h4 class="emphasis-text text-center">
              <i class="fa-solid fa-hourglass-half"></i> <i class="fa-solid fa-triangle-exclamation"></i>
              {{ autoCancellationLeadTimeMessage }}
            </h4>
            <h6 class="emphasis-text text-center">{{ $t('wizard.for_questions_message') }}</h6>
          </template>
          <!-- Without auto cancellation lead time -->
          <template v-else>
            <h4 class="emphasis-text text-center">
              {{ $t('wizard.payment_message') }}
            </h4>
            <h6 class="emphasis-text text-center">{{ $t('wizard.suggest_payment_message') }}</h6>
          </template>
        </template>
        <!-- Register payment workflow -->
        <template v-else-if="shouldRegisterPaymentMethod">
          <!-- With auto cancellation lead time -->
          <template v-if="hasAutoCancellationLeadTime">
            <h4 class="emphasis-text text-center">
              <i class="fa-solid fa-triangle-exclamation"></i>
              {{ $t('wizard.lead_time_require_payment_information_message') }}
            </h4>
            <h6 class="emphasis-text text-center">
              <i class="fa-solid fa-hourglass-half"></i>
              {{ autoCancellationLeadTimeMessage + ' ' + $t('wizard.no_charge_will_be_done_message') }}
            </h6>
          </template>
          <!-- Without auto cancellation lead time -->
          <template v-else>
            <h4 class="emphasis-text text-center">
              {{ $t('wizard.require_payment_information_message') }}
            </h4>
            <h6 class="emphasis-text text-center">{{ $t('wizard.no_charge_will_be_done_message') }}</h6>
          </template>
        </template>
        <!-- No payment workflow -->
        <template v-else>
          <h4 class="emphasis-text text-center">{{ !isReferrals ? $t('wizard.invitation_sent_message') : $t('wizard.invitation_sent_message_referrals') }}</h4>
        </template>
        <div class="button"
          v-if="shouldShowPaymentsButton && (!isReferrals || shouldRegisterPaymentMethod)"
          @click="onContinueToPayments"
        >
          <span >{{ paymentsButtonText }}</span>
        </div>
        <span v-else>
          <h3 class="step__final-message" v-if="locationConfig.location.finalMessage">{{ locationConfig.location.finalMessage}}</h3>
        </span>
      </section>
    </transition>

    <!-- STEP 7: ERROR WITH RETRY  -->
    <transition :name="transitions['7']">
      <section class="step step--compacted step--undivided" v-show="currentStep === STEPS.RETRYABLE_ERROR">
        <!-- Slot already taken (455)-->
        <RetryableErrorView
          v-if="errorCode === ERROR_CODES.SLOT_ALREADY_TAKEN"
          :titleText="$t('errors.scheduling.slot_already_taken_title')"
          :bodyText="$t('errors.scheduling.slot_already_taken_body')"
          :buttonText="$t('wizard.go_back')"
          @buttonClicked="goToStep(STEPS.DATE)"
        />
        <!-- Event errors (470 to 489)-->
        <RetryableErrorView
          v-else-if="errorCode >= 470 && errorCode < 490"
          :titleText="$t('errors.scheduling.events_title')"
          :bodyText="$t('errors.scheduling.events_body', { officePhone })"
          @buttonClicked="goToStep(STEPS.FORM)"
        />
        <!-- Contact errors (490 to 499)-->
        <RetryableErrorView
          v-else-if="errorCode >= 490 && errorCode < 500"
          :titleText="$t('errors.scheduling.contacts_title')"
          :bodyText="$t('errors.scheduling.contacts_body', { officePhone })"
          @buttonClicked="goToStep(STEPS.FORM)"
        />
        <!-- General errors -->
        <RetryableErrorView
          v-else
          :titleText="$t('errors.scheduling.general_title')"
          :bodyText="$t('errors.scheduling.general_body', { officePhone })"
          @buttonClicked="goToStep(STEPS.DATE)"
        />
      </section>
    </transition>

    <div class="confirm-footer" v-if="currentStep === STEPS.CONFIRM">
      <img src="https://s3.amazonaws.com/assets.nimblr.ai/brand/logos/WhiteIsotype.png">
    </div>
  </div>
  <FooterSummary
    v-if="shouldShowFooterSummary"
    :provider="providerSelected.name"
    :date="dateSelected"
    :is-healthcare-specialty="isHealthcareSpecialty"
    :currentStep="currentStep"
    :shouldAskProvider="shouldAskProvider"
  />
  <Footer v-else-if="shouldShowFooter" :currentStep="currentStep"/>
</template>

<script lang="ts">
/* eslint-disable no-mixed-operators */
// Components
import AppointmentTypesList from '@/components/AppointmentTypesList.vue'
import Calendar from '@/components/Calendar.vue'
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import IconArrowCircle from '@/components/icons/IconArrowCircle.vue'
import IconLoading from '@/components/icons/IconLoading.vue'
import ImagePicker from '@/components/ImagePicker.vue'
import InputSelect from '@/components/Inputs/InputSelect.vue'
import InputText from '@/components/Inputs/InputText.vue'
import InputTextArea from '@/components/Inputs/InputTextArea.vue'
import MockContent from '@/components/MockContent.vue'
import ProvidersList from '@/components/ProvidersList.vue'
import RetryableErrorView from '@/components/errors/RetryableErrorView.vue'
import FooterSummary from '@/components/summaries/FooterSummary.vue'
import Summary from '@/components/summaries/Summary.vue'
import TimezoneSelector from '@/components/TimezoneSelector.vue'
import TopBanner from '@/components/banners/TopBanner.vue'
// Config
import config from '@/config'
// Data
import countryCodes from '@/data/countryCodes'
import extendedInsurancePlanTypes from '@/data/extendedInsurancePlanTypes'
import autoFillableUserDataFromQueryParams from '@/data/autoFillableUserDataFromQueryParams'
import usStates from '@/data/usStates'
// Services
import ApiService from '@/services/ApiService'
import CatalogsService from '@/services/CatalogsService'
import UtilService from '@/services/UtilService'
// Store
import { store } from '@/store'
// Types
import { AppointmentType } from '@/types/AppointmentType'
import { CalendarProfile } from '@/types/CalendarProfile'
import { ErrorCodes } from '@/types/ErrorCodes'
import { LocationConfig } from '@/types/LocationConfig'
import { Location } from '@/types/Location'
import { Provider } from '@/types/Provider'
import { Slot } from '@/types/Slot'
// Vue's
import { Options, Vue } from 'vue-class-component'
import { mask } from 'vue-the-mask'

@Options({
  components: {
    AppointmentTypesList,
    Calendar,
    Footer,
    Header,
    IconArrowCircle,
    IconLoading,
    ImagePicker,
    InputSelect,
    InputText,
    InputTextArea,
    MockContent,
    ProvidersList,
    RetryableErrorView,
    FooterSummary,
    Summary,
    TimezoneSelector,
    TopBanner
  },
  directives: { mask },
  watch: {
    '$route.query': {
      handler: 'onRouteQueryChange'
    }
  },
  computed: {
    isHealthcareSpecialty () {
      if (this.locationConfig.config.isHealthcareSpecialty !== undefined) return this.locationConfig.config.isHealthcareSpecialty
      return true
    }
  }
})
export default class AppointmentWizard extends Vue {
  STEPS = {
    INBOUND_NEGATIVE_CONDITIONAL: -1,
    SELF_SCHEDULING_WARNING: 0,
    APPOINTMENT: 1,
    DOCTOR: 2,
    DATE: 3,
    TIME: 4,
    FORM: 5,
    CONFIRM: 6,
    RETRYABLE_ERROR: 7
  }

  BIRTHDATE_INPUT: Record<string, Record<string, string>> = {
    day: {
      placeholder: 'DD',
      maxlength: '2'
    },
    month: {
      placeholder: 'MM',
      maxlength: '2'
    },
    year: {
      placeholder: 'YYYY',
      maxlength: '4'
    }
  }

  // Client data
  clientIP = ''
  /* LOADED DATA */

  loading = true
  loadingSlotsCalendar = true
  locationConfig = { location: {}, config: {}, googleAnalyticsFlags: {} } as LocationConfig
  appointmentTypes: AppointmentType[] = []
  appointmentTypesBackupOrder: AppointmentType[] = []
  providers: Provider[] = []
  slotsInMonth: any = {}
  slots: Slot[] = []
  states: any[] = []
  providersStock: any = {}
  calendarProfile: CalendarProfile = { address: '' }
  countryCodes = countryCodes
  usStates: any = usStates
  extendedInsurancePlanTypes: any = extendedInsurancePlanTypes
  autoFillableUserDataFromQueryParams: string[] = autoFillableUserDataFromQueryParams
  autoFillableUserData: Record<string, string> = {}
  hasAutoFilledTheUserData = false
  isReferrals = false
  shouldIgnoreSelfSchedulingWarning = false
  reservationId = ''
  accountName = ''
  googleReservationAddress = ''

  // Self scheduling texts
  selfSchedulingTexts: Record<string, Record<string, string>> = {}

  /* SELECTED VALUES */

  appointmentTypePreSelected: any = {}
  appointmentTypeSelected: any = {}
  providerPreSelected: any = {}
  providerSelected: any = {}
  dateSelected = new Date()
  slotSelected: any = {}
  stateSelected = ''
  countryCodeSelected = countryCodes[0]
  chooseSecure = ''
  url = true
  insuranceCardChecked = false
  useIframe = false
  form: any = {
    referrer: {},
    referrerFirstName: '',
    referrerLastName: '',
    referrerEmail: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    visitReason: '',
    birthdate: {
      day: '',
      month: '',
      year: ''
    },
    legalSex: '',
    address: '',
    zipCode: '',
    city: '',
    state: '',
    extraQuestions: [],
    insuranceCardFront: '',
    insuranceCardBack: '',
    ssn: '',
    insuranceCompany: '',
    insuranceGroupNumber: '',
    insuranceIdNumber: '',
    insurancePlanType: ''
  }

  theTermsAndConditionsWereAccepted = false

  /* VIEW STATES */

  appointmentHasBeenRegistered = false
  currentStep = this.STEPS.APPOINTMENT
  stateSelectionActive = false
  initializationDone = false
  pageSize = 'mobile'
  savingAppointment = false
  currentYear = new Date().getFullYear()
  countryCodesActive = false
  thereIsAvailableDates = true
  areCustomServiceStates = false
  shouldShowGoogleAddress = false
  transitions: Record<string, string>= {
    '-1': 'fade-out',
    0: 'fade-out',
    1: 'fade-out',
    2: 'fade-out',
    3: 'fade-out',
    4: 'fade-out',
    5: 'fade-out',
    6: 'fade-out',
    7: 'fade-out'
  }

  /* ERROR MANAGE */
  ERROR_CODES = ErrorCodes
  errorCode = 455
  NON_RETRYABLE_ERROR_CODES = [
    ErrorCodes.FAILED_DEPENDENCY,
    ErrorCodes.GATEWAY_TIMEOUT,
    ErrorCodes.CONTACT_BLOCKED
  ]

  /* APP GLOBAL VARS */

  get lang () : string {
    return store.state.lang
  }

  get customTexts () : Record<string, Record<string, string>> {
    return store.state.customTexts
  }

  get selfSchedulingWarning () : string {
    return this.getFormattedSelfSchedulingText('inbound_warning')
  }

  get colors () : Record<string, unknown> {
    return store.state.colors
  }

  get timezoneSelected () : string {
    return store.state.timezone
  }

  get loadingSlots () : boolean {
    return store.state.loadingSlots
  }

  get formErrors () : Array<string> {
    return store.state.formErrors
  }

  get isMobile () : boolean {
    return this.pageSize === 'mobile'
  }

  get isDesktop () : boolean {
    return this.pageSize === 'desktop'
  }

  // Calculated
  get canGoBack () : boolean {
    return ((this.currentStep > 1 && this.currentStep < 6) || this.currentStep === 1 && Boolean(this.stateSelected)) && !this.savingAppointment && !this.appointmentHasBeenRegistered
  }

  get shouldShowFooterSummary (): boolean {
    const isOnRequiredSteps = this.currentStep > this.STEPS.DOCTOR && this.currentStep < this.STEPS.FORM
    const hasNecessaryInfo = (this.shouldAskProvider && this.providerSelected.name) || this.currentStep >= this.STEPS.TIME
    return this.isMobile && isOnRequiredSteps && hasNecessaryInfo
  }

  get shouldShowFooter (): boolean {
    const shouldShowOnDesktop = this.isDesktop && this.currentStep !== this.STEPS.CONFIRM
    const shouldShowOnMobile = this.isMobile && (this.currentStep < this.STEPS.CONFIRM || this.currentStep === this.STEPS.RETRYABLE_ERROR)
    return shouldShowOnDesktop || shouldShowOnMobile
  }

  get accountCountry () : string {
    return this.locationConfig && this.locationConfig.location && this.locationConfig.location.country || 'US'
  }

  get isUSAccount () : boolean {
    return this.accountCountry === 'US'
  }

  get officePhone () : string {
    return store.state.officePhone
  }

  get truncatedAccountName () : string {
    return this.accountName.length > 30 ? this.accountName.substring(0, 30) + '...' : this.accountName
  }

  get shouldAskProvider () : boolean {
    return this.locationConfig.config.askProvider
  }

  get isAnyProviderWorkflowEnabled () : boolean {
    return this.shouldAskProvider && this.locationConfig.config.isAnyProviderWorkflowEnabled
  }

  get shouldShowAnyProviderButton () : boolean {
    return this.isAnyProviderWorkflowEnabled && this.providers.length >= 2
  }

  get isAnyProviderSelected () :boolean {
    return this.isAnyProviderWorkflowEnabled && (!this.providerSelected || !Object.prototype.hasOwnProperty.call(this.providerSelected, 'id'))
  }

  get shouldShowSummaryProviderName (): boolean {
    // When no ask for provider, just show provider after an slot is selected
    if (!this.shouldAskProvider) return this.currentStep >= this.STEPS.FORM
    // If the any provider workflow is not enabled, show always the provider name
    if (!this.isAnyProviderWorkflowEnabled) return true
    // If the any provider workflow is enabled and any provider is selected, just show provider after an slot is selected. Else show the provider name
    return this.isAnyProviderSelected ? this.currentStep >= this.STEPS.FORM : true
  }

  get summaryProviderName () : string {
    // When no ask for provider
    if (!this.shouldAskProvider) {
      // Get name from slot selected
      const nameFromSlotSelected = this.slotSelected.origin && this.slotSelected.origin.organizerName
      if (nameFromSlotSelected) return nameFromSlotSelected
    }
    // When ask for provider
    else {
      // Get name from provider selected
      const nameFromProviderSelected = this.providerSelected.name
      if (nameFromProviderSelected) return nameFromProviderSelected
      // Or get name from slot selected if any provider workflow is enabled
      if (this.isAnyProviderWorkflowEnabled) return this.providers.find(p => p.id === this.slotSelected.organizer)?.name || ''
    }
    // Any other case
    return ''
  }

  get summaryAddress () : string {
    const summaryAddress = this.calendarProfile && this.calendarProfile.address || this.locationConfig.location.address || ''
    const urlsRegex = /(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)/g
    return summaryAddress.replace(urlsRegex, '')
  }

  get summaryTime () : string {
    let timeString = this.slotSelected.start
    // Get preferences for config
    const shouldAddEndTime = !this.locationConfig.config.shouldHideAppointmentEndTime
    const shouldAddDuration = !this.locationConfig.config.shouldHideAppointmentDuration
    // Add the end time to the time string
    if (shouldAddEndTime) timeString += ' - ' + this.slotSelected.end
    // Add the duration to the time string
    if (shouldAddDuration) timeString += ' (' + this.getDurationSlot() + ' min)'
    return timeString
  }

  get scheduledButtonShouldBeEnable () : boolean {
    const noAskingTermsAndConditions = !this.locationConfig.config.askTermsAndConditions
    const theTermsAndConditionsWereAccepted = this.locationConfig.config.askTermsAndConditions && this.theTermsAndConditionsWereAccepted
    const termsAndConditionsMisconfigured = this.locationConfig.config.askTermsAndConditions && !this.locationConfig.config.termsAndConditionsLink
    return noAskingTermsAndConditions || theTermsAndConditionsWereAccepted || termsAndConditionsMisconfigured || this.isReferrals
  }

  // Array with order of inputs for birthdate
  get birthdateInputs (): Array<string> {
    return this.accountCountry === 'US' ? ['month', 'day', 'year'] : ['day', 'month', 'year']
  }

  get isValidBirthdate (): boolean {
    // If doesn't have any of the three principal values (day, month or year), is invalid
    if (!this.form.birthdate.day || !this.form.birthdate.month || !this.form.birthdate.year) return false

    // Simplify refs and define limitations
    const day = Number(this.form.birthdate.day)
    const month = Number(this.form.birthdate.month)
    const year = Number(this.form.birthdate.year)
    const SUPPORTED_AGE = 150

    // Validate that the values are inside valid ranges
    if (day < 1 || day > 31) return false
    if (month < 1 || month > 12) return false
    if (year < (this.currentYear - SUPPORTED_AGE) || year > this.currentYear) return false

    // Validate if birth date is before today's date (For current year). If it's not before, is invalid
    const today = new Date()
    const birthday = new Date(year, month - 1, day)
    if (birthday > today) return false

    // Validate by month
    const monthsWith31Days = [1, 3, 5, 7, 8, 10, 12] // January, March, May, July, August, October, December
    const monthsWith30Days = [4, 6, 9, 11] // April, June, September and November
    // If the month is (January, March, May, July, August, October, December), which have 31 days return valid, no more validations required.
    if (monthsWith31Days.includes(month)) return true
    // If the month is (April, June, September and November), which have 30 days, validate that the day is 30 or less. If it is not, is invalid.
    if (monthsWith30Days.includes(month)) return day <= 30

    // February validations
    // If the selected day is higher than 29, is invalid
    if (day > 29) return false
    // If the selected day is 29, we should validate if the selected year is leap. If it is not, is invalid
    if (day === 29) return this.isLeapYear(year)
    // Another case (February 28th or less and any valid year)
    return true
  }

  get availableStates () {
    const availableStates: any = {}
    const statesWithAvailableService = this.locationConfig.config.serviceStates || []
    // If are not custom service states, we will use all states
    if (!statesWithAvailableService.length) {
      for (const usState in this.usStates) {
        availableStates[usState] = this.usStates[usState]
      }
      return availableStates
    }
    // Else we well use just the customs
    for (const stateWithAvailableService of statesWithAvailableService) {
      for (const usState in this.usStates) {
        if (stateWithAvailableService === usState) {
          availableStates[usState] = this.usStates[usState]
        }
      }
    }
    // Enable warning message
    this.areCustomServiceStates = true
    return availableStates
  }

  get shouldAskNewPatientQuestions () : boolean {
    const appointmentTypeForNewPatients = this.appointmentTypeSelected && this.appointmentTypeSelected.isForNewPatients
    return appointmentTypeForNewPatients
  }

  get extendedInsurancePlanTypesToSelect () {
    const planTypesToSelect: any = {}
    const locationPlanTypes = this.locationConfig.location && this.locationConfig.location.extendedInsurancePlanTypes || []
    for (const locationPlanType of locationPlanTypes) {
      for (const planType in this.extendedInsurancePlanTypes) {
        if (locationPlanType === planType) {
          planTypesToSelect[planType] = this.extendedInsurancePlanTypes[planType]
        }
      }
    }
    return planTypesToSelect
  }

  get insuranceWarning () : string {
    return this.locationConfig.config.wsCustomTextForInsuranceWarning
  }

  get shouldShowInsuranceWarning () : boolean {
    const config = this.locationConfig.config
    return (config.insuranceCard || config.ssn || config.goingToGatherExtendedInsuranceInformation || this.isReferrals) && Boolean(this.insuranceWarning)
  }

  get shouldUseInsuranceWorkflow () : boolean {
    return this.locationConfig.config.insuranceCard && this.locationConfig.config.ssn
  }

  get shouldJustUseInsuranceCard (): boolean {
    return this.locationConfig.config.insuranceCard && !this.locationConfig.config.ssn
  }

  get shouldJustUseSSN (): boolean {
    return this.locationConfig.config.ssn && !this.locationConfig.config.insuranceCard
  }

  get shouldAskInsuranceCard () : boolean {
    if (this.shouldJustUseInsuranceCard) return true
    if (this.shouldUseInsuranceWorkflow) {
      const workflow = this.locationConfig.config.insuranceWorkflow
      if (workflow !== 'excluding') return true
    }
    return false
  }

  get shouldAskSSN () : boolean {
    if (this.shouldJustUseSSN) return true
    if (this.shouldUseInsuranceWorkflow) {
      const workflow = this.locationConfig.config.insuranceWorkflow
      if (workflow !== 'excluding') return true
      if (this.chooseSecure === 'ssn') return true
    }
    return false
  }

  get snnFinalLabel (): string {
    let finalLabel = this.locationConfig.config.ssnLabel ? this.locationConfig.config.ssnLabel : this.$t('wizard.form_field_ssn')
    const insuranceWorkflow = this.locationConfig.config.insuranceWorkflow
    if (insuranceWorkflow === 'mandatory' || insuranceWorkflow === 'excluding' || this.locationConfig.config.ssnMandatory) finalLabel += ' *'
    return finalLabel
  }

  get paymentWorkflowType (): string {
    // From api if paymentEnabled from account is false or location.payable is false paymentWorkflowType is returned as "none"
    return this.locationConfig.config.paymentWorkflowType || 'none'
  }

  get shouldProcessPayment (): boolean {
    // For process Payments
    if (this.paymentWorkflowType === 'process-payment') {
      const price = this.appointmentTypeSelected.price || 0
      const appointmentHasValidPrice = Boolean(!isNaN(price) && (Number(price) > 0))
      // Show if appointment is configured to pay
      if (this.appointmentTypeSelected.payable && appointmentHasValidPrice) return true
    }
    return false
  }

  get shouldRegisterPaymentMethod (): boolean {
    // For register payment method
    if (this.paymentWorkflowType === 'payment-method') return true
    return false
  }

  get shouldShowPaymentsButton (): boolean {
    return this.shouldProcessPayment || this.shouldRegisterPaymentMethod
  }

  get hasAutoCancellationLeadTime (): boolean {
    const rawLeadTime = this.locationConfig.config.autoCancellationLeadTime || -1
    return rawLeadTime > 0
  }

  get paymentsButtonText (): string {
    // When process payment
    if (this.shouldProcessPayment) return this.hasAutoCancellationLeadTime ? this.$t('wizard.continue_to_pay') : this.$t('wizard.pay_now')
    // When register paymentMethod
    if (this.shouldRegisterPaymentMethod) return this.$t('wizard.complete_information')
    return ''
  }

  get completeSchedulingHeader (): string {
    // When has not auto cancellation lead time
    if (!this.hasAutoCancellationLeadTime) return this.$t('wizard.confirmed_message')
    // When process payment
    if (this.shouldProcessPayment) return this.$t('wizard.reserved_message')
    // When register paymentMethod
    if (this.shouldRegisterPaymentMethod) return this.$t('wizard.details_message')
    // Default Case
    return this.$t('wizard.confirmed_message')
  }

  get autoCancellationLeadTimeMessage (): string {
    const rawLeadTime = this.locationConfig.config.autoCancellationLeadTime || -1
    // if there is no lead time return empty message
    if (rawLeadTime < 0) return ''
    let formattedLeadTime
    // The lead time is on minutes
    if ((rawLeadTime % 1) !== 0) {
      formattedLeadTime = this.$tc('time.minutes', rawLeadTime * 60, { minutes: rawLeadTime * 60 })
    }
    // THe lead time is on hours
    else {
      formattedLeadTime = this.$tc('time.hours', rawLeadTime, { hours: rawLeadTime })
    }

    // When process payment
    if (this.shouldProcessPayment) return this.$t('wizard.lead_time_payment_message', { leadTime: formattedLeadTime })
    // When register paymentMethod
    if (this.shouldRegisterPaymentMethod) return this.$t('wizard.lead_time_to_finish_message', { leadTime: formattedLeadTime })
    // Another case
    return ''
  }

  get shouldAskConsentToText (): boolean {
    return this.isUSAccount && this.locationConfig.config.askConsentToText
  }

  get shouldPushEmailFormError (): boolean {
    // Email is mandatory if is not referrals workflow or should register payment method
    const isMandatory = !this.isReferrals || this.shouldRegisterPaymentMethod
    // If email is empty: It should push email form error if it's mandatory, else, if it's optional, it shouldn't push error
    if (!this.form.email) return isMandatory
    // Email is not empty: Return the regx validation of the email, if is a valid email, it shouldn't push email form error, else, it should push the error.
    return !this.form.email.toLowerCase().match(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/)
  }

  /* REFS */
  declare $refs: {
    calendar: HTMLFormElement
  }

  /* LIFECYCLE */

  created () {
    window.addEventListener('resize', this.onPageSize)
    this.onPageSize()
  }

  destroyed () {
    window.removeEventListener('resize', this.onPageSize)
  }

  async mounted () {
    const params = this.$route.params as any
    const query = this.$route.query as any
    if (query.referrals) {
      this.isReferrals = Boolean(query.referrals)
    }
    if (query.ignoreSelfSchedulingWarning) {
      this.shouldIgnoreSelfSchedulingWarning = Boolean(query.ignoreSelfSchedulingWarning)
    }
    this.identifyAutoFillableUserDataFormFromQueryParams(query)
    this.locationConfig = await ApiService.getLocation(params.hashtag, query.iframe, navigator.language, navigator.userAgent)
    const { location = {} as Location } = this.locationConfig
    this.locationConfig.googleAnalyticsFlags = { visitStep: false, viewProviderStep: false, viewFormStep: false, scheduledStep: false }
    this.locationConfig.googleAnalyticsFlags.visitStep = UtilService.updateGAFlag(this.locationConfig.config.idGA, this.locationConfig.googleAnalyticsFlags.visitStep)
    const error = (this.locationConfig as any).error
    const vm = this
    const rootElement = document.getElementById('app')

    // Set custom texts
    if (this.locationConfig?.config?.customTexts) store.commit('setCustomTexts', this.locationConfig.config.customTexts)

    // Set self scheduling texts
    this.selfSchedulingTexts = this.locationConfig.config.selfSchedulingTexts || {}

    // Self scheduling warning workflow
    if (this.selfSchedulingWarning && !this.shouldIgnoreSelfSchedulingWarning) {
      // Set step to show self scheduling warning
      this.currentStep = this.STEPS.SELF_SCHEDULING_WARNING
    }
    // Button Colors
    const userColors = this.colors

    // Default Colors
    if (this.locationConfig.config.colors.background) {
      rootElement!.style.setProperty('--background-color', this.locationConfig.config.colors.background)
      rootElement!.style.setProperty('--background-color-other-button', this.locationConfig.config.colors.background)
      userColors.backgroundColor = this.locationConfig.config.colors.background
    }
    if (this.locationConfig.config.colors.border) {
      rootElement!.style.setProperty('--border-color', this.locationConfig.config.colors.border)
      userColors.borderColor = this.locationConfig.config.colors.border
    }
    if (this.locationConfig.config.colors.text) {
      rootElement!.style.setProperty('--text-color', this.locationConfig.config.colors.text)
      rootElement!.style.setProperty('--text-color-other-button', this.locationConfig.config.colors.text)
      rootElement!.style.setProperty('--primary-light-color', this.locationConfig.config.colors.text)
      userColors.textColor = this.locationConfig.config.colors.text
    }
    // Hover/Selected Colors
    if (this.locationConfig.config.colors.hoverSelectedBackground) {
      rootElement!.style.setProperty('--hover-selected-background-color', this.locationConfig.config.colors.hoverSelectedBackground)
      userColors.hoverSelectedBackgroundColor = this.locationConfig.config.colors.hoverSelectedBackground
    }
    else {
      rootElement!.style.setProperty('--hover-selected-background-color', String(userColors.textColor))
      userColors.hoverSelectedBackgroundColor = userColors.textColor
    }
    if (this.locationConfig.config.colors.hoverSelectedBorder) {
      rootElement!.style.setProperty('--hover-selected-border-color', this.locationConfig.config.colors.hoverSelectedBorder)
      userColors.hoverSelectedBorderColor = this.locationConfig.config.colors.hoverSelectedBorder
    }
    else {
      rootElement!.style.setProperty('--hover-selected-border-color', String(userColors.backgroundColor))
      userColors.hoverSelectedBorderColor = userColors.backgroundColor
    }
    if (this.locationConfig.config.colors.hoverSelectedText) {
      rootElement!.style.setProperty('--hover-selected-text-color', this.locationConfig.config.colors.hoverSelectedText)
      userColors.hoverSelectedTextColor = this.locationConfig.config.colors.hoverSelectedText
    }
    else {
      rootElement!.style.setProperty('--hover-selected-text-color', String(userColors.backgroundColor))
      userColors.hoverSelectedTextColor = userColors.backgroundColor
    }
    // Update colors
    store.commit('changeColors', userColors)

    // Patient flags
    if (this.locationConfig && this.locationConfig.config && this.locationConfig.config.patientFlags) {
      const flags = Object.keys(this.locationConfig.config.patientFlags)
      for (const flag of flags) {
        if (query[flag]) {
          this.locationConfig.config.patientFlags[flag] = query[flag]
        }
      }
    }
    // Has office phone
    if (this.locationConfig?.location?.phone) {
      store.commit('changeOfficePhone', this.locationConfig.location.phone)
    }
    // Has Country
    if (this.locationConfig && this.locationConfig.location && this.locationConfig.location.country) {
      for (const countryCode of this.countryCodes) {
        if (countryCode.key === this.locationConfig.location.country.toLocaleLowerCase()) {
          this.countryCodeSelected = countryCode
          break
        }
      }
    }

    // Google Reservation
    if (location.isGoogleReservationEnabled && query.isFromGoogleReservation && location.googleReservationAddress) {
      this.googleReservationAddress = location.googleReservationAddress
      this.accountName = location.accountName
      this.shouldShowGoogleAddress = true
    }

    if (error) {
      switch (error.statusCode) {
        case 404:
          this.$router.push({ name: 'error', query: { code: ErrorCodes.HASHTAH_NOT_FOUND } })
          break
        case 500:
          this.$router.push({ name: 'error', query: { code: ErrorCodes.SERVER_ERROR } })
          break
      }
      return
    }
    if (this.locationConfig.config.locationsByState || this.locationConfig.config.appointmentTypesByState || this.locationConfig.config.providersByState) {
      if (query.state) {
        this.recoverViewStateWithParams(query)
      }
      else {
        this.stateSelectionActive = true
        this.states = CatalogsService.getStatesCatalogAvailable(this.locationConfig.location.states)
      }
    }
    else {
      this.appointmentTypes = await ApiService.getAppointmentTypes(this.locationConfig.location.userId, this.locationConfig.location.id)
      this.appointmentTypesBackupOrder = this.appointmentTypes
      this.recoverViewStateWithParams(query)
      setTimeout(() => {
        this.initializationDone = true
      }, 1500)
    }
  }

  async recoverViewStateWithParams (query: any) {
    if (query.state) {
      await this.onStateSelected(query.state)
    }

    if (query.iframe) {
      this.useIframe = true
    }

    if (query.type) {
      this.appointmentTypeSelected = this.appointmentTypes.find(it => it.id === query.type) || {}

      if (this.appointmentTypeSelected.id && this.appointmentTypes.length) {
        if (this.shouldAskProvider) {
          await this.loadProviders()
          this.currentStep = this.STEPS.DOCTOR
          if (query.dr && this.providers.length) {
            this.providerSelected = this.providers.find(it => it.id === query.dr) || {}
            this.currentStep = this.STEPS.DATE
          }
        }
        else {
          this.currentStep = this.STEPS.DATE
        }
      }
    }
  }

  async loadProviders () {
    this.providers = this.providersStock[this.appointmentTypeSelected.id] || await ApiService.getProviders(this.locationConfig.location.userId, this.locationConfig.location.id, this.appointmentTypeSelected.id, this.locationConfig.config.funnelId, this.stateSelected, 'web-scheduler')
    this.providersStock[this.appointmentTypeSelected.id] = this.providers
    this.locationConfig.googleAnalyticsFlags.viewProviderStep = UtilService.updateGAFlag(this.locationConfig.config.idGA, this.locationConfig.googleAnalyticsFlags.viewProviderStep, 'view-provider', this.appointmentTypeSelected.name, this.useIframe)
  }

  /* On state selection (Only for US) */
  async onStateSelected (state: string) {
    this.stateSelectionActive = false
    this.stateSelected = state
    this.providersStock = {}
    this.appointmentTypes = await ApiService.getAppointmentTypes(this.locationConfig.location.userId, this.locationConfig.location.id, this.stateSelected, 'web-scheduler')
    this.appointmentTypesBackupOrder = this.appointmentTypes
    setTimeout(() => {
      this.initializationDone = true
    }, 1500)
  }

  /* On appointment type selection */
  onClickAppointmentTypeButton (selected: any) {
    const selectedHasNotDescription = !(selected.description)
    const selectedHasNotPrice = Number(selected.price) === 0
    if ((selectedHasNotDescription && selectedHasNotPrice) || this.appointmentTypePreSelected === selected) {
      this.appointmentTypeSelected = selected
      // Hide any visible appointment type descriptions when an appointment types without description is selected
      if (selectedHasNotDescription && selectedHasNotPrice) this.appointmentTypePreSelected = {}
      this.updateQueryParams()
    }
    else {
      this.appointmentTypeSelected = {}
      this.goToStep(1)
      setTimeout(() => {
        this.appointmentTypePreSelected = selected
      }, 200)
    }

    if (this.appointmentTypeSelected === selected) {
      if (this.shouldAskProvider) {
        this.loadProviders()
        this.goToStep(2)
        this.appointmentTypes = this.appointmentTypes.filter(appointmentType => appointmentType !== this.appointmentTypeSelected)
        this.appointmentTypes.unshift(this.appointmentTypeSelected)
      }
      else {
        this.goToStep(3)
      }
    }
  }

  /* On any provider button pressed */
  onClickAnyProviderButton () {
    this.providerSelected = {}
    this.goToStep(this.STEPS.DATE)
  }

  /* On provider selected */
  onClickProviderButton (selected: any) {
    const selectedHasNotDescription = (selected.description === '' || typeof selected.description === 'undefined')
    if (selectedHasNotDescription || this.providerPreSelected === selected) {
      this.providerPreSelected = {}
      this.providerSelected = selected
      this.goToStep(3)
      this.updateQueryParams()
    }
    else {
      this.providerPreSelected = selected
    }
  }

  /* On date selection in calendar */
  onDateSelected (payload: any) {
    this.dateSelected = payload.date
    this.slots = payload.slots
    setTimeout(() => {
      this.goToStep(4)
    }, 300)
  }

  /* On slot item selection */
  async onClickSlotButton (slot: any) {
    this.locationConfig.googleAnalyticsFlags.viewFormStep = UtilService.updateGAFlag(this.locationConfig.config.idGA, this.locationConfig.googleAnalyticsFlags.viewFormStep, 'view-form', undefined, this.useIframe)
    ApiService.slotSelectedGA(this.locationConfig.config.funnelId, slot)
    this.slotSelected = slot
    if (!this.appointmentTypeSelected.isVirtual) this.calendarProfile = await ApiService.getCalendarProfile(slot.calendarId)
    setTimeout(() => {
      this.goToStep(5)
    }, 300)
  }

  /* Save appointment */
  onFormComplete () {
    if (!this.scheduledButtonShouldBeEnable || this.savingAppointment) return
    if (this.validateForm()) {
      setTimeout(async () => {
        try {
          this.locationConfig.googleAnalyticsFlags.scheduledStep = UtilService.updateGAFlag(this.locationConfig.config.idGA, this.locationConfig.googleAnalyticsFlags.scheduledStep, 'scheduled', undefined, this.useIframe)
          this.savingAppointment = true
          const appointmentData = await this.getAppointmentData()
          const response = await ApiService.saveAppointment(appointmentData)
          if (response.reservation) {
            this.reservationId = response.reservation.id
            if (response.reservation.finalMessage) this.locationConfig.location.finalMessage = response.reservation.finalMessage
            this.$router.push({ query: {} })
            setTimeout(() => {
              this.goToStep(6)
            }, 2300)
          }
          else {
            this.savingAppointment = false
            // Set returned error status code
            if (response.error.statusCode) this.errorCode = response.error.statusCode

            // Non retryable errors, move to error page.
            if (this.NON_RETRYABLE_ERROR_CODES.includes(this.errorCode)) {
              this.$router.push({ name: 'error', query: { code: this.errorCode } })
              return
            }

            // RETRYABLE ERRORS
            // Error 455: Scheduled slot already taken
            if (this.errorCode === ErrorCodes.SLOT_ALREADY_TAKEN) {
              this.$refs.calendar.resetCalendarState()
              this.goToStep(this.STEPS.RETRYABLE_ERROR)
              return
            }
            // Event errors (470 to 489)
            if (this.errorCode >= 470 && this.errorCode < 490) {
              this.goToStep(this.STEPS.RETRYABLE_ERROR)
              return
            }
            // Contact errors (490 to 499)
            if (this.errorCode >= 490 && this.errorCode < 500) {
              this.goToStep(this.STEPS.RETRYABLE_ERROR)
              return
            }
            // General errors
            this.$refs.calendar.resetCalendarState()
            this.goToStep(this.STEPS.RETRYABLE_ERROR)
          }
        }
        catch (err) {
          this.savingAppointment = false
          // If the timeout ends, abort the request and redirect to Gateway Timeout error page
          if (err.name === 'AbortError') {
            this.$router.push({ name: 'error', query: { code: ErrorCodes.GATEWAY_TIMEOUT } })
            return
          }
          // Override error code to fall on generic retryable error
          this.errorCode = -1
          this.$refs.calendar.resetCalendarState()
          this.goToStep(this.STEPS.RETRYABLE_ERROR)
        }
      }, 300)
    }
  }

  /* Redirect Payments */
  onContinueToPayments () {
    const paymentUrl = config.paymentsUrl + '?rid=' + this.reservationId
    window.location.href = paymentUrl
  }

  /* VIEW STATE METHODS */

  onRouteQueryChange (query: any) {
    if (query.s) {
      if (this.canGoBack) {
        this.goToStep(parseInt(query.s))
      }
    }
  }

  onPageSize () {
    if (window.innerWidth >= 700) {
      this.pageSize = 'desktop'
    }
    else {
      this.pageSize = 'mobile'
    }
  }

  updateForm (data:any) {
    if (data.name === 'frontImage') {
      this.form.insuranceCardFront = data.info
    }
    else {
      this.form.insuranceCardBack = data.info
    }
  }

  updateAddFormErrors (ban:string) {
    this.pushToFormErrors(ban)
  }

  updateDelFormErrors (ban:string) {
    const newFormErrors:string[] = this.formErrors
    newFormErrors.splice(newFormErrors.indexOf(ban), 1)
    store.commit('changeFormErrors', newFormErrors)
  }

  changeFormInputValue (formAttribute: string, value: string) {
    this.form[formAttribute] = value
  }

  pushToFormErrors (errorTag: string) {
    const newFormErrors: string[] = this.formErrors
    newFormErrors.push(errorTag)
    store.commit('changeFormErrors', newFormErrors)
  }

  validateForm () {
    // Reset formErrors
    store.commit('changeFormErrors', [])

    this.form.phoneNumber = this.form.phoneNumber.replace(/-/g, '').replace(/\)/g, '').replace(/ /g, '').replace(/\(/g, '')

    if (this.isReferrals && (!this.form.referrer.email || !this.form.referrer.email.toLowerCase().match(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/))) {
      this.pushToFormErrors('input-referrer-email')
    }

    if (!this.form.firstName) {
      this.pushToFormErrors('input-firstName')
    }
    if (!this.form.lastName) {
      this.pushToFormErrors('input-lastName')
    }

    const phoneNumberLength = this.form.phoneNumber.length
    const phoneNumberHasValidLength = this.countryCodeSelected.validLengths.includes(phoneNumberLength)
    const isValidPhoneNumber = /[1-9]\d/.test(this.form.phoneNumber) && phoneNumberHasValidLength
    if (!isValidPhoneNumber) {
      this.pushToFormErrors('input-phone')
    }
    if (this.shouldPushEmailFormError) {
      this.pushToFormErrors('input-email')
    }
    if (this.locationConfig.config.askDateOfBirth || this.isReferrals) {
      if (!this.form.birthdate.day) {
        this.pushToFormErrors('input-birthday-day')
      }
      if (!this.form.birthdate.month) {
        this.pushToFormErrors('input-birthday-month')
      }
      if (!this.form.birthdate.year) {
        this.pushToFormErrors('input-birthday-year')
      }
      if (this.form.birthdate.day && this.form.birthdate.month && this.form.birthdate.year && !this.isValidBirthdate) {
        this.pushToFormErrors('input-birthday-day')
        this.pushToFormErrors('input-birthday-month')
        this.pushToFormErrors('input-birthday-year')
      }
    }

    if (this.locationConfig.config.askLegalSex || this.isReferrals) {
      if (!this.form.legalSex) {
        this.pushToFormErrors('input-legalSex')
      }
    }

    // Address people
    if (this.locationConfig.config.askAddress || this.isReferrals) {
      if (!this.form.address) {
        this.pushToFormErrors('input-address')
      }

      if (!this.form.zipCode || this.form.zipCode.length < 4) {
        this.pushToFormErrors('input-zipCode')
      }

      if (!this.form.city) {
        this.pushToFormErrors('input-city')
      }

      if (!this.form.state) {
        this.pushToFormErrors('input-state')
      }
    }

    // New patient questions
    if (this.shouldAskNewPatientQuestions) {
      // Zip code
      if (!this.form.zipCode || this.form.zipCode.length < 4) {
        this.pushToFormErrors('input-zipCode')
      }
      // State
      if (!this.form.state) {
        this.pushToFormErrors('input-state')
      }
    }

    // Insurance card and SSN Validations
    // Asking for insurance card and SSN
    if (this.shouldUseInsuranceWorkflow) {
      switch (this.locationConfig.config.insuranceWorkflow) {
        case 'mandatory':
          this.validateInsuranceCard()
          this.validateSSN()
          break
        case 'excluding':
          if (this.chooseSecure === 'insurance') {
            this.form.ssn = ''
            this.validateInsuranceCard()
          }
          else if (this.chooseSecure === 'ssn') {
            this.form.insuranceCardFront = ''
            this.form.insuranceCardBack = ''
            this.validateSSN()
          }
          else {
            this.pushToFormErrors('input-radio')
          }
          break
        default:
        // Optional (Do not validate anything)
          break
      }
    }
    // Asking for insurance Card only
    if (this.shouldJustUseInsuranceCard && this.locationConfig.config.insuranceCardMandatory) {
      this.validateInsuranceCard()
    }
    // Asking for SSN only
    if (this.shouldJustUseSSN && this.locationConfig.config.ssnMandatory) {
      this.validateSSN()
    }

    // Extended insurance information
    if (this.locationConfig.config.goingToGatherExtendedInsuranceInformation || this.isReferrals) {
      if (this.locationConfig.config.mandatoryExtendedInsuranceQuestions.includes('InsuranceCompany') && !this.form.insuranceCompany) {
        this.pushToFormErrors('input-insuranceCompany')
      }
      if (this.locationConfig.config.mandatoryExtendedInsuranceQuestions.includes('GroupId') && !this.form.insuranceGroupNumber) {
        this.pushToFormErrors('input-insuranceGroupNumber')
      }
      if (this.locationConfig.config.mandatoryExtendedInsuranceQuestions.includes('MemberId') && !this.form.insuranceIdNumber) {
        this.pushToFormErrors('input-insuranceIdNumber')
      }
      if (this.locationConfig.config.mandatoryExtendedInsuranceQuestions.includes('PlanType') && !this.form.insurancePlanType && Object.keys(this.extendedInsurancePlanTypesToSelect).length) {
        this.pushToFormErrors('input-insurancePlanType')
      }
    }

    // Visit Reason
    if (this.locationConfig.config.showVisitReasonQuestion && this.locationConfig.config.visitReasonAsMandatoryQuestion && !this.form.visitReason) {
      this.pushToFormErrors('input-visitReason')
    }
    // Additional questions
    if (this.locationConfig.config.additionalQuestionsAsMandatoryQuestions && !this.isReferrals) {
      for (const additionalQuestionIndex in this.locationConfig.location.additionalQuestions) {
        if (!this.form.extraQuestions[additionalQuestionIndex]) {
          this.pushToFormErrors('input-extra-question' + additionalQuestionIndex)
        }
      }
    }

    // Consent to text
    if (this.shouldAskConsentToText && !this.form.consentToText) this.formErrors.push('input-consent-to-text')

    // Validate if there are errors
    if (this.formErrors.length) {
      UtilService.focusOnElement(this.formErrors[0])
      return false
    }
    return true
  }

  validateInsuranceCard () : void {
    if (!this.form.insuranceCardFront && !this.isReferrals) {
      this.pushToFormErrors('input-image-frontImage-not-provided')
    }
    if (!this.form.insuranceCardBack && !this.isReferrals) {
      this.pushToFormErrors('input-image-backImage-not-provided')
    }
  }

  validateSSN () : void {
    if (this.form.ssn === '') {
      this.pushToFormErrors('input-ssn')
    }
  }

  updateQueryParams () {
    const query: any = {}
    if (this.isReferrals) {
      query.referrals = this.isReferrals
    }
    if (this.shouldIgnoreSelfSchedulingWarning) query.ignoreSelfSchedulingWarning = this.shouldIgnoreSelfSchedulingWarning

    if (typeof this.$route.query.mandatory !== 'undefined') {
      query.mandatory = this.$route.query.mandatory
    }

    query.s = this.currentStep
    if (this.stateSelected) {
      query.state = this.stateSelected
    }
    if (this.appointmentTypeSelected.id) {
      query.type = this.appointmentTypeSelected.id
    }
    if (this.providerSelected.id) {
      query.dr = this.providerSelected.id
    }
    this.$router.push({ query })
  }

  // Take the query params and identify which can auto-fill the user data on the form fields and save them on an object (this doesn't fill the user data)
  identifyAutoFillableUserDataFormFromQueryParams (query: Record<string, string>) : void {
    const paramsKeys = Object.keys(query)
    for (const paramKey of paramsKeys) {
      if (autoFillableUserDataFromQueryParams.includes(paramKey)) {
        this.autoFillableUserData[paramKey] = query[paramKey]
      }
    }
  }

  // Auto-fill the user data on the form fields according to the data type (special validations are identified here)
  autoFillUserData () : void {
    const dataTypes = Object.keys(this.autoFillableUserData)
    for (const dataType of dataTypes) {
      const userData = this.autoFillableUserData[dataType]
      switch (dataType) {
        case 'phoneNumber': {
          this.autoFillPhoneNumber(userData)
          break
        }
        case 'dateOfBirth': {
          this.autoFillDateOfBirth(userData)
          break
        }
        case 'legalSex': {
          this.autoFillLegalSex(userData)
          break
        }
        case 'userState': {
          this.autoFillState(userData)
          break
        }
        case 'insurancePlanType': {
          this.autoFillInsurancePlanType(userData)
          break
        }
        // On no special cases just auto fill the user data
        default: {
          this.form[dataType] = userData
        }
      }
    }
    // Remember the user data has been auto-filled
    this.hasAutoFilledTheUserData = true
  }

  autoFillPhoneNumber (phoneNumberForAutoFill : string) : void {
    // Removing mask to the phone, just keep digits and "+" symbol if it has
    const numberWithoutMask = phoneNumberForAutoFill.replace(/[^0-9+]+/g, '')
    // If prefix exists, remove it (at the moment hard code US prefix)
    const prefix = '+1'
    const numberWithoutPrefix = numberWithoutMask.replace(prefix, '')
    // Auto-fill phone number
    this.form.phoneNumber = numberWithoutPrefix
  }

  autoFillDateOfBirth (dateOfBirthForAutoFill : string) : void {
    // Default format "yyyy-mm-dd"
    // Split the formatted DOB on units time (year, months and days)
    const unitsTime = dateOfBirthForAutoFill.split('-')
    // If there is year
    if (unitsTime[0]) this.form.birthdate.year = Number(unitsTime[0])
    // If there is month
    if (unitsTime[1]) this.form.birthdate.month = Number(unitsTime[1])
    // If there is day
    if (unitsTime[2]) this.form.birthdate.day = Number(unitsTime[2])
  }

  autoFillLegalSex (legalSexForAutoFill : string) : void {
    const supportedLegalSex = ['M', 'F', 'O']
    if (supportedLegalSex.includes(legalSexForAutoFill)) this.form.legalSex = legalSexForAutoFill
  }

  autoFillState (stateForAutoFill : string) : void {
    const supportedStates = Object.keys(this.availableStates)
    if (supportedStates.includes(stateForAutoFill)) this.form.state = stateForAutoFill
  }

  autoFillInsurancePlanType (insurancePlanTypeForAutoFill : string) : void {
    const availableExtendedInsurancePlanTypes = Object.keys(this.extendedInsurancePlanTypesToSelect)
    if (availableExtendedInsurancePlanTypes.includes(insurancePlanTypeForAutoFill)) this.form.insurancePlanType = insurancePlanTypeForAutoFill
  }

  getDurationSlot () {
    if (typeof this.slotSelected.start !== 'undefined' && typeof this.slotSelected.end !== 'undefined') {
      const slotStart = this.slotSelected.start.split(' ')[0].split(':')
      const slotEnd = this.slotSelected.end.split(' ')[0].split(':')
      let hr
      let min
      if (parseInt(slotEnd[0]) < parseInt(slotStart[0])) {
        hr = parseInt(slotEnd[0]) + 12 - parseInt(slotStart[0])
        min = parseInt(slotEnd[1]) - parseInt(slotStart[1])
      }
      else {
        hr = parseInt(slotEnd[0]) - parseInt(slotStart[0])
        min = parseInt(slotEnd[1]) - parseInt(slotStart[1])
      }
      return (hr * 60) + min
    }
    return ''
  }

  async getAppointmentData () {
    this.form.phoneNumber = this.form.phoneNumber.replace(/-/g, '').replace(/\)/g, '').replace(/ /g, '').replace(/\(/g, '')
    const baseObject: any = {
      userId: this.locationConfig.location.userId.toString(),
      locationId: this.locationConfig.location.id,
      appointmentTypeId: this.appointmentTypeSelected.id,
      slot: this.slotSelected.origin,
      firstName: this.form.firstName.trim(),
      lastName: this.form.lastName.trim(),
      phoneNumber: this.countryCodeSelected.code + ' ' + this.form.phoneNumber,
      email: this.form.email,
      language: this.lang,
      timeZone: this.timezoneSelected,
      funnelId: '' + this.locationConfig.config.funnelId,
      referrer: {}
    }

    if (this.isReferrals) {
      baseObject.referrer = {
        email: this.form.referrer.email,
        firstName: this.form.referrerFirstName,
        lastName: this.form.referrerLastName,
        address: this.form.referrerAddress
      }
    }

    if (this.locationConfig.config.askDateOfBirth || this.isReferrals) {
      baseObject.dateOfBirth = `${this.form.birthdate.month.toString().padStart(2, '0')}/${this.form.birthdate.day.toString().padStart(2, '0')}/${this.form.birthdate.year}`
    }

    if (this.locationConfig.config.askLegalSex || this.isReferrals) {
      baseObject.legalSex = this.form.legalSex
    }

    // Address people
    if (this.locationConfig.config.askAddress || this.isReferrals) {
      baseObject.address = this.form.address
      baseObject.zipCode = this.form.zipCode
      baseObject.city = this.form.city
      baseObject.state = this.form.state
    }

    // New patient questions
    if (this.shouldAskNewPatientQuestions) {
      baseObject.zipCode = this.form.zipCode
      baseObject.state = this.form.state
    }

    if (this.locationConfig.config.patientFlags && Object.keys(this.locationConfig.config.patientFlags).length > 0) {
      baseObject.patientFlags = this.locationConfig.config.patientFlags
    }

    if (this.providerSelected.id) {
      baseObject.providerId = '' + this.providerSelected.id
    }

    if (this.form.visitReason) {
      baseObject.visitReason = this.form.visitReason
    }

    if (this.form.extraQuestions.length) {
      baseObject.extraQuestionsAnswers = this.form.extraQuestions.join('|')
    }

    if (this.form.insuranceCardFront.length) {
      baseObject.insuranceCardFront = this.form.insuranceCardFront
    }
    if (this.form.insuranceCardBack.length) {
      baseObject.insuranceCardBack = this.form.insuranceCardBack
    }

    if (this.form.ssn.length) {
      baseObject.ssn = this.form.ssn.replace(/-/gi, '')
    }

    // Extended Insurance Information
    if (this.locationConfig.config.goingToGatherExtendedInsuranceInformation || this.isReferrals) {
      baseObject.insuranceCompany = this.form.insuranceCompany
      baseObject.insuranceGroupNumber = this.form.insuranceGroupNumber
      baseObject.insuranceIdNumber = this.form.insuranceIdNumber
      baseObject.insurancePlanType = this.form.insurancePlanType
    }

    // Consent to text
    if (this.shouldAskConsentToText && this.form.consentToText) {
      baseObject.consentToText = this.form.consentToText === 'true'
      baseObject.clientIP = await UtilService.getClientIP()
    }

    return baseObject
  }

  goToStep (step: number) : void {
    if (step === 0) {
      this.initializationDone = false
      this.stateSelectionActive = true
      this.stateSelected = ''
      this.currentStep = this.STEPS.APPOINTMENT
      this.updateQueryParams()
      return
    }
    if (step === 2 && !this.shouldAskProvider) {
      step = 1
    }
    if (this.shouldAskProvider) {
      if (step === this.STEPS.APPOINTMENT && (JSON.stringify(this.appointmentTypeSelected) !== '{}')) {
        this.appointmentTypes = this.appointmentTypesBackupOrder
      }
    }
    if (step === this.STEPS.APPOINTMENT) {
      this.appointmentTypePreSelected = {}
      this.appointmentTypeSelected = {}
    }
    if (step === this.STEPS.DOCTOR) {
      this.providerPreSelected = {}
      this.providerSelected = {}
    }
    if (step === this.STEPS.DOCTOR || step === this.STEPS.DATE) {
      if (!this.isMobile) this.scrollTo('#location-name')
    }
    if (step === this.STEPS.FORM) {
      this.url = !this.url
      this.form.insuranceCardFront = ''
      this.form.insuranceCardBack = ''
      if (!this.hasAutoFilledTheUserData) this.autoFillUserData()
    }
    if (step === this.STEPS.CONFIRM) {
      this.appointmentHasBeenRegistered = true
    }
    // Reset no available dates message
    this.resetNoAvailableDatesMessage(step)

    this.updateStepTransitions(this.currentStep, step)
    this.currentStep = step
    this.updateQueryParams()
  }

  updateStepTransitions (prevStep: number, nextStep: number) {
    if (this.isMobile) {
      this.transitions[String(prevStep)] = nextStep > prevStep ? 'fade-out' : 'fade-in'
      this.transitions[String(nextStep)] = nextStep > prevStep ? 'fade-in' : 'fade-out'
    }
    else {
      this.transitions[String(prevStep)] = 'fade'
      this.transitions[String(nextStep)] = 'fade'
    }
  }

  resetNoAvailableDatesMessage (step: number) {
    if (this.locationConfig.config.availableDatesOnMemory) {
      if (step === this.STEPS.APPOINTMENT || step === this.STEPS.DOCTOR) {
        // Reset AvailableDates flag
        if (!this.thereIsAvailableDates) {
          this.thereIsAvailableDates = true
        }
        // To detect and position even if is the same provider o appointmentType
        if (this.shouldAskProvider) {
          this.providerSelected = ''
        }
        else {
          this.appointmentTypeSelected = {}
        }
      }
    }
  }

  scrollTo (elementSelector: string) : void {
    setTimeout(() => {
      const elementToScroll = document.querySelector(elementSelector)
      if (elementToScroll) {
        elementToScroll.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      }
    }, 100)
  }

  selectCountryCode (item: any) {
    this.countryCodeSelected = item
    this.countryCodesActive = false
    UtilService.focusOnElement('input-phone')
  }

  updateLoading (data: boolean) {
    this.loading = data
  }

  updateLoadingSlots (data: boolean) {
    this.loadingSlotsCalendar = data
  }

  useCustomText (customTextKey: string, defaultText: string) : string {
    return this.customTexts && this.customTexts[this.lang] && this.customTexts[this.lang][customTextKey] || defaultText
  }

  // Utils
  getOptimalSelfSchedulingText (textId: string, extraDataForLocales: Record<string, string> = {}) : string {
    const referenceTexts = Object.assign({}, this.selfSchedulingTexts)
    // Look for the text with the current language of the ws. If it was found,return it.
    const hasTextForCurrentWSLanguage = Object.prototype.hasOwnProperty.call(referenceTexts, this.lang) && Object.prototype.hasOwnProperty.call(referenceTexts[this.lang], textId)
    if (hasTextForCurrentWSLanguage) return referenceTexts[this.lang][textId]
    // Remove language from the referenceTexts to don't repeat the search in the follow validation languages
    delete referenceTexts[this.lang]

    // Look for the text with the main language of the account. If it was found, return it.
    const mainLanguage = this.locationConfig.config.mainLanguage || 'en'
    const hasTextForMainLanguage = this.lang !== mainLanguage && Object.prototype.hasOwnProperty.call(referenceTexts, mainLanguage) && Object.prototype.hasOwnProperty.call(referenceTexts[mainLanguage], textId)
    if (hasTextForMainLanguage) return referenceTexts[mainLanguage][textId]
    // Remove language from the referenceTexts to don't repeat the search in the follow validation languages
    delete referenceTexts[mainLanguage]

    // Look for the text with another languages and return the first text found
    for (const texts of Object.values(referenceTexts)) {
      if (Object.prototype.hasOwnProperty.call(texts, textId)) return texts[textId]
    }

    // If there is no text found, check for ws locales
    if (this.$te('self_scheduling.' + textId)) return this.$t('self_scheduling.' + textId, extraDataForLocales)
    // else return an empty string
    return ''
  }

  getFormattedSelfSchedulingText (textId: string, extraDataForLocales: Record<string, string> = {}) : string {
    return this.substituteCustomTextBlueLabels(this.getOptimalSelfSchedulingText(textId, extraDataForLocales))
  }

  substituteCustomTextBlueLabels (message: string): string {
    // For profile.work blue label (location phone)
    // eslint-disable-next-line no-template-curly-in-string
    message = message.replaceAll('${profile.work}', this.locationConfig.location.phone)
    return message
  }

  acceptJustDigits (event: KeyboardEvent) : undefined {
    // Validate
    const keyCode = event.keyCode || event.which
    if (keyCode === 8) return
    // Just numbers
    const pattern = /\d/
    const key = String.fromCharCode(keyCode)
    if (!pattern.test(key)) event.preventDefault()
  }

  isLeapYear (year: number): boolean {
    // If there is no year selected we assume that is a leap year
    if (!year) return true
    // If has value, we validate it
    if (year % 400 === 0) return true
    if (year % 100 === 0) return false
    return year % 4 === 0
  }
}
</script>
