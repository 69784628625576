import { store } from '@/store'
export default {
  computed: {
    colors () {
      return store.state.colors
    },
    useBackgroundAndTextColorsForConfirmFigure () : boolean {
      // Workaround to read the color subject on typescript
      let userColors: any = {}
      userColors = this.colors
      return userColors.backgroundColor === userColors.borderColor
    },

    useHoverSelectedBackgroundAndTextColorsForConfirmFigure () : boolean {
      // Workaround to read the color subject on typescript
      let userColors: any = {}
      userColors = this.colors
      return userColors.hoverSelectedBackgroundColor === userColors.hoverSelectedBorderColor
    }
  }
}
