<template>
  <div
    v-for="(appointmentType, index) in appointmentTypes"
    :key="appointmentType.id"
    @click="$emit('clickAppointmentTypeButton', appointmentType)"
    class="button flex no-padding"
    :class="{'button--selected': appointmentType === appointmentTypeSelected}"
  >
    <div class="appointmentType-info">
      <!-- Name -->
      <div class="appointmentType-name" :class="{'appointmentType-name-preselected': appointmentTypePreSelected == appointmentType}">
        {{ appointmentType.name }}
      </div>
      <!-- Description -->
      <div v-if="appointmentType.description?.length > 0 && appointmentTypePreSelected == appointmentType" class="appointmentType-description">
        <span v-if="!isShowingFullDescription[index]">{{appointmentType.description?.length > 60 ? appointmentType.description?.slice(0,57)+ '...' : appointmentType.description}}</span>
        <span v-else>{{appointmentType.description}}</span>
        <!-- Toggle full description buttons -->
        <div v-if="appointmentType.description?.length > 60 && appointmentTypePreSelected == appointmentType" class="button__description-control-buttons">
          <span v-if="!isShowingFullDescription[index]" @click="updateAppointmentTypeDescription(index, true)"><i class="fa fa-plus-circle"></i></span>
          <span v-else @click="updateAppointmentTypeDescription(index, false)"><i class="fa fa-minus-circle"></i></span>
        </div>
      </div>
      <!-- Price -->
      <div v-if="appointmentTypePreSelected == appointmentType" class="appointmentType-price">
        <span v-if="!isShowingFullDescription[index] && appointmentType.price != 0">$ {{appointmentType.price}}</span>
      </div>
    </div>
    <!-- Confirm figure -->
    <div v-if="appointmentTypePreSelected == appointmentType" class="button__confirm-arrow" :class="confirmAppointmentTypeExtraClasses">
      <IconArrowCircle class="button__confirm-arrow-svg" direction="right"/>
    </div>
  </div>
</template>

<script>
import IconArrowCircle from '@/components/icons/IconArrowCircle.vue'
import userColorsMixin from '@/mixins/userColorsMixin.ts'
import { store } from '@/store'
export default {
  components: {
    IconArrowCircle
  },
  props: {
    appointmentTypes: {
      type: Array,
      required: true
    },
    appointmentTypePreSelected: {
      type: Object,
      default () {
        return { }
      }
    },
    appointmentTypeSelected: {
      type: Object,
      default () {
        return { }
      }
    }
  },
  data () {
    return {
      isShowingFullDescription: []
    }
  },
  computed: {
    currentStep () {
      return this.$parent.$parent.currentStep
    },
    confirmAppointmentTypeExtraClasses () {
      return {
        'button__confirm-arrow--contrasted': this.useBackgroundAndTextColorsForConfirmFigure || this.useHoverSelectedBackgroundAndTextColorsForConfirmFigure
      }
    }
  },
  watch: {
    currentStep (currentStep) {
      if (currentStep === 1) {
        // Close descriptions opened
        this.isShowingFullDescription = {}
      }
    }
  },
  methods: {
    updateAppointmentTypeDescription (index, value) {
      event.stopPropagation()
      this.isShowingFullDescription[index] = value
    }
  },
  emits: ['clickAppointmentTypeButton', 'resetAppointmentTypePreSelected'],
  mixins: [userColorsMixin]
}
</script>

<style lang="scss" scoped>
.appointmentType-info {
  padding: 12px;
  width: 100%;
}

.appointmentType-name {
  margin: auto;
}

.appointmentType-name-preselected {
  font-size: 1.1rem;
}

.appointmentType-description {
  margin: 15px 0;
  font-size: 0.9rem;
  text-align: left;
}

.appointmentType-price {
  margin-top: 15px;
  font-size: 2.25rem;
}
</style>
