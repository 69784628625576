<template>
  <div class="mock-content">
    <div class="mock-col" v-for="(col, c_index) of cols" :key="c_index" :style="{ width: 'calc(' + 100 / cols + '%' + ' - 5px)' }">
      <div class="mock-row" v-for="(row, r_index) of rows" :key="r_index" :style="{ height: rowHeight }"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    rows: Number,
    cols: Number,
    rowHeight: String
  }
})
export default class MockContent extends Vue {
  rows!: number
  cols!: number
  rowHeight!: string
}
</script>

<style scoped>
.mock-content {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.mock-content .mock-col {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  width: 100%;
}

.mock-content .mock-row {
  position: relative;
  border-radius: 5px;
  margin-bottom: 12px;
  animation: mock-row-animate 1s linear infinite;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  background: linear-gradient(to right, #f1f1f1 8%, #fcfcfc 38%, #f1f1f1 54%);
  background-size: 200% 100%;
}

@keyframes mock-row-animate {
  0% {
    background-position-x: -100%;
  }
  70% {
    background-position-x: 100%;
  }
  100% {
    background-position-x: 100%;
  }
}
</style>
