<template>
    <div :class="calculatedClass">
        <label :for="'input-'+name">{{ calculatedLabel + (isMandatory ? ' *' : '')}}</label>
        <input type="text" :id="'input-'+name" v-model.trim="form[name]" :placeholder="calculatedPlaceholder" @keypress="validateText($event)" :maxlength="maxLength">
    </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default {
  props: {
    // Prop to name input and his attributes (id, for) and how will add in the geneal form.
    name: {
      type: String,
      required: true
    },
    // The label will shown in the input form.
    label: {
      type: String,
      default: ''
    },
    // Placeholder text, if it is not defined, it will use the label
    placeholder: {
      type: String,
      default: ''
    },
    // Define if the element will be validate
    isMandatory: {
      type: Boolean,
      default: false
    },
    // Just accept digit numbers
    acceptJustNumbers: {
      type: Boolean,
      default: false
    },
    // Just accept letters
    acceptJustLetters: {
      type: Boolean,
      default: false
    },
    // To define size, "full": 100%, "half": 50%
    size: {
      type: String,
      default: 'half'
    },
    // Max length for the input
    maxLength: {
      type: String,
      default: '250'
    }
  },
  computed: {
    calculatedClass () {
      const calculatedClass = {
        error: this.formErrors.includes('input-' + this.name) && this.isMandatory,
        'full-size': this.size === 'full',
        'half-size': this.size === 'half'
      }
      return calculatedClass
    },
    calculatedLabel () {
      return this.label || this.labelFromName
    },
    calculatedPlaceholder () {
      return this.isMandatory ? (this.placeholder || this.placeholderFromName || this.calculatedLabel) : (this.placeholder || this.$t('wizard.form_field_placeholder_optional'))
    },
    form () {
      return this.$parent.$parent.form
    },
    formErrors () {
      return this.$store.state.formErrors
    },
    labelFromName () {
      // lowerCamelCase to snakeCase
      const nameAsSnakeCase = this.name.replace(/([A-Z])/g, ' $1').replaceAll(' ', '_').toLowerCase()
      const localeKey = 'wizard.form_field_' + nameAsSnakeCase
      return this.$t(localeKey)
    },
    placeholderFromName () {
      // lowerCamelCase to snakeCase
      const nameAsSnakeCase = this.name.replace(/([A-Z])/g, ' $1').replaceAll(' ', '_').toLowerCase()
      const localeKey = 'wizard.form_field_' + nameAsSnakeCase + '_placeholder'
      // Verify the locale exist
      const localePlaceholder = (this.$te(localeKey) && this.$t(localeKey)) || localeKey
      return localePlaceholder !== localeKey ? localePlaceholder : ''
    }
  },
  methods: {
    validateText (event) {
      // If is not necessary validate
      if (!this.acceptJustNumbers && !this.acceptJustLetters) return
      // Validate
      const keyCode = event.keyCode || event.which
      if (keyCode === 8) return
      let pattern
      // Just numbers
      if (this.acceptJustNumbers) pattern = /\d/
      // Just letters
      if (this.acceptJustLetters) pattern = /[A-Za-z\s]/
      const key = String.fromCharCode(keyCode)
      if (!pattern.test(key)) event.preventDefault()
    }
  }
}
</script>

<style lang="scss" scoped>
div {
    margin-bottom: 12px;
    label {
        display: block;
        margin-bottom: 5px;
        font: bold;
        font-weight: bolder;
    }
    input{
        width: 100%;
        min-height: 50px;
        padding: 0 15px;
        outline: none;
        border: 1px solid #cccccc;
        border-radius: 4px;
        box-shadow: 1px 1px 7px -1px #b6b6b6;
        font-size: 1.2rem;
        font-family: inherit;
        &::placeholder {
            color: #ccc;
            font-size: 1.1rem;
        }
    }
    &.error {
        input {
            border: 2px solid red;
        }
    }
}

.full-size {
    width: 100%;
}

.half-size {
    width: 48%;
}

// Mobile
@media (max-width: 700px) {
    .full-size, .half-size {
    width: 100%;
    }
}

// Dark Schema
@media (prefers-color-scheme: dark) {
    div {
        input {
            box-shadow: none;
        }
    }
}
</style>
