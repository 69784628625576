
import { Vue } from 'vue-class-component'
import { store } from '@/store'
import UtilService from '@/services/UtilService'

export default class TimezoneSelector extends Vue {
  timezones = UtilService.getTimezones()
  openSelector = false
  inputSearch = ''

  get lang () {
    return store.state.lang
  }

  get timezone () {
    return store.state.timezone
  }

  formatTimezone (original: string): string {
    const auxDate = new Date()
    const formatter = new Intl.DateTimeFormat(this.lang, {
      timeZone: original,
      timeZoneName: 'short'
    })
    const abbrev = formatter.formatToParts(auxDate).find((part) => part.type === 'timeZoneName')?.value || ''
    return `${original.replaceAll('_', ' ')} (${abbrev})`
  }

  select (timezone: string) {
    if (this.timezone !== timezone) {
      store.commit('changeTimezone', timezone)
      this.$emit('select', timezone)
    }
    this.openSelector = false
  }
}
