

export default {
  props: {
    // Title of the error view.
    titleText: {
      type: String,
      required: true
    },
    // Body of the error view.
    bodyText: {
      type: String,
      default: ''
    },
    // To know that the retryable error doesn't have button.
    withoutButton: {
      type: Boolean,
      default: false
    },
    // Text in the button.
    buttonText: {
      type: String
    },
    // To know if the retryable is half screen.
    isHalfScreen: {
      type: Boolean,
      default: false
    }
  },
  emits: ['buttonClicked']
}
