
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    type: String,
    title: String,
    formErrors: {
      type: Array,
      required: true
    },
    url: Boolean
  },
  watch: {
    url () {
      this.urlImage = ''
    }
  }
})

export default class ImagePicker extends Vue {
  type!: string
  title!: string
  formErrors: string[] = []
  file: any = {}
  urlImage = ''

  async onFileChange (e:any) {
    try {
      this.file = e.target.files[0]
      if (typeof this.file !== 'undefined') {
        this.urlImage = ''
        if (this.formErrors.indexOf(`input-image-${this.type}`) !== -1) {
          this.$emit('updateDelFormErrors', `input-image-${this.type}`)
        }
        if (this.formErrors.indexOf(`input-image-size-${this.type}`) !== -1) {
          this.$emit('updateDelFormErrors', `input-image-size-${this.type}`)
        }
        if (/(.jpg|.jpeg|.png|.gif)$/i.test(this.file.name)) {
          if (this.file.size > 10000000) {
            this.$emit('updateAddFormErrors', `input-image-size-${this.type}`)
            this.$emit('updateForm', {
              name: this.type,
              info: ''
            })
          }
          else {
            this.urlImage = URL.createObjectURL(this.file)
            const baseImage = await this.compressImage(e.target.files[0])
            this.$emit('updateForm', {
              name: this.type,
              info: baseImage
            })
            this.$emit('updateDelFormErrors', `input-image-${this.type}-not-provided`)
          }
        }
        else {
          this.$emit('updateAddFormErrors', `input-image-${this.type}`)
          this.$emit('updateForm', {
            name: this.type,
            info: ''
          })
        }
        var input = document.createElement('input')
        input.type = 'file'
        e.target.files = input.files
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  compressImage (file:any) {
    const vm = this
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function (event) {
        const imgElement = document.createElement('img')
        imgElement.src = URL.createObjectURL(file)
        imgElement.onload = function (e) {
          const target = e.target as HTMLCanvasElement
          const canvas = document.createElement('canvas')
          const MAX_SIZE = 600
          const originalWidth = target?.width || 0
          const originalHeight = target?.height || 0

          if (originalWidth <= MAX_SIZE && originalHeight <= MAX_SIZE) {
            canvas.width = originalWidth
            canvas.height = originalHeight
          }
          else if (originalWidth >= originalHeight) {
            const scaleSize = MAX_SIZE / originalWidth
            canvas.width = MAX_SIZE
            canvas.height = originalHeight * scaleSize
          }
          else {
            const scaleSize = MAX_SIZE / originalHeight
            canvas.width = originalWidth * scaleSize
            canvas.height = MAX_SIZE
          }

          const ctx = canvas.getContext('2d')

          ctx!.drawImage(target!, 0, 0, canvas.width, canvas.height)

          canvas.toBlob(function (blob) {
            var newReader = new FileReader()
            newReader.readAsDataURL(vm.blobToFile(blob, 'compress_image.jpg')!)
            newReader.onloadend = function () {
              var base64data = newReader.result
              resolve(base64data)
            }
          }, 'image/jpeg', 0.85)
        }
      }
    })
  }

  blobToFile (theBlob: Blob | null, fileName:string) {
    var b: any = theBlob
    b.lastModifiedDate = new Date()
    b.name = fileName
    return theBlob
  }

  toBase64 (file : any) {
    return new Promise((resolve: any, reject:any) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  clearUrl () {
    this.urlImage = ''
    this.$emit('updateForm', {
      name: this.type,
      info: ''
    })
  }
}
