
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    rows: Number,
    cols: Number,
    rowHeight: String
  }
})
export default class MockContent extends Vue {
  rows!: number
  cols!: number
  rowHeight!: string
}
