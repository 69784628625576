
import { store } from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
  },
  props: {
    // Provider selected
    provider: {
      type: String,
      default: undefined
    },
    // Date selected
    date: {
      type: Date,
      default: null
    },
    // Is the account a healthcare specialty
    isHealthcareSpecialty: {
      type: Boolean,
      required: true
    },
    // Current step of the schedule process
    currentStep: {
      type: Number,
      required: true
    },
    // If the provider should be asked
    shouldAskProvider: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    lang () : string {
      return store.state.lang
    },
    formattedDate () : string {
      // If there is no date, return an empty string
      if (!this.date) return ''
      // Else return the date in the format: Jan 1, 2021
      const formatted = this.date.toLocaleString(this.lang, {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })
      return formatted.charAt(0).toUpperCase() + formatted.slice(1)
    },
    shouldShowProviderData () : boolean {
      return Boolean(this.provider) && this.shouldAskProvider
    },
    shouldShowDateData () : boolean {
      return this.currentStep >= 4
    }
  }
})
