
import { Vue, Options } from 'vue-class-component'

@Options({
  props: {
    direction: String
  }
})
export default class IconArrowCircle extends Vue {
  direction!: string
}
