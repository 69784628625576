import UtilService from '@/services/UtilService'

const formatter = Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })

export class Slot {
  calendarId: string
  start: string
  end: string
  origin: any
  organizer: string

  constructor (origin: any) {
    this.origin = origin
    this.calendarId = origin.calendarId
    this.start = formatter.format(UtilService.getOriginalDate(origin.start))
    this.end = formatter.format(UtilService.getOriginalDate(origin.end))
    this.organizer = origin.organizer
  }
}
