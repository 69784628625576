import { CalendarProfile } from '@/types/CalendarProfile'
import { LocationConfig } from '@/types/LocationConfig'
import UtilService from './UtilService'
import config from '@/config'

declare let gtag: any

export default class ApiService {
  static async getLocation (hashtag: string, iframe: string, languageNavigator: string, userAgent: string): Promise<LocationConfig> {
    const result = await fetch(`${config.apiUrl}/Inbounds/location?hashtag=${hashtag}&languageNavigator=${languageNavigator}&userAgent=${userAgent}`)
    const data = await result.json()
    if (data && data.config && data.config.idGA) {
      if (iframe) {
        // i --> Google Analytics id, a --> action, c --> category, l --> label
        window.top.postMessage({ i: data.config.idGA, a: 'visit', c: 'holly', l: hashtag }, '*')
      }
      else {
        gtag('config', data.config.idGA)
        gtag('event', 'visit', { event_category: 'holly', event_label: hashtag })
      }
    }
    return data
  }

  static async getAppointmentTypes (userId: number, locationId: string, state?: string, source?: string): Promise<any[]> {
    const result = await fetch(`${config.apiUrl}/Inbounds/appointmentTypes?userId=${userId}&locationId=${locationId}&state=${state || ''}&source=${source || ''}`)
    const data = await result.json()
    return data.appointmentTypes.map((type:any) => { return { ...type, description: !type.description ? '' : type.description } })
  }

  static async getProviders (userId: number, locationId: string, appointmentTypeId: number, funnelId: string, state?: string, source?: string): Promise<any[]> {
    const result = await fetch(`${config.apiUrl}/Inbounds/providers?userId=${userId}&locationId=${locationId}&appointmentTypeId=${appointmentTypeId}&funnelId=${funnelId}&state=${state || ''}&source=${source || ''}`)
    const data = await result.json()
    return data.providers
  }

  static async getSlots (userId: number, locationId: string, appointmentTypeId: string, providerId: string, funnelId: string, timeZone: string, year: number, month: number, day: number, stateSelected: string): Promise<any[]> {
    const initDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
    const endDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
    const result = await fetch(`${config.apiUrl}/Inbounds/availableSlots?userId=${userId}&locationId=${locationId}&appointmentTypeId=${appointmentTypeId}&providerId=${providerId}&funnelId=${funnelId}&timeZone=${timeZone}&startDate=${initDate}&endDate=${endDate}&stateSelected=${stateSelected}`)
    const data = await result.json()
    return data.slots
  }

  static async getAvailableDates (userId: number, locationId: string, appointmentTypeId: string, providerId: string, year: number, month: number, funnelId: string, timezone: string, stateSelected: string): Promise<any[]> {
    const initDate = `${year}-${month.toString().padStart(2, '0')}-01`
    const endDate = `${year}-${month.toString().padStart(2, '0')}-${UtilService.getDaysInMonth(year, month).toString().padStart(2, '0')}`
    const result = await fetch(`${config.apiUrl}/Inbounds/availableDates?userId=${userId}&locationId=${locationId}&appointmentTypeId=${appointmentTypeId}&providerId=${providerId}&funnelId=${funnelId}&startDate=${initDate}&endDate=${endDate}&externalTimezone=${timezone}&state=${stateSelected}`)
    const data = await result.json()
    return data.availableDates
  }

  static async getAnyProviderAvailableDates (userId: number, locationId: string, appointmentTypeId: string, providerIds: Array<string>, year: number, month: number, funnelId: string, timezone: string): Promise<any[]> {
    const initDate = `${year}-${month.toString().padStart(2, '0')}-01`
    const endDate = `${year}-${month.toString().padStart(2, '0')}-${UtilService.getDaysInMonth(year, month).toString().padStart(2, '0')}`
    const encodedProviderIds = encodeURIComponent(JSON.stringify(providerIds))
    const result = await fetch(`${config.apiUrl}/Inbounds/anyProviderAvailableDates?userId=${userId}&locationId=${locationId}&appointmentTypeId=${appointmentTypeId}&encodedProviderIds=${encodedProviderIds}&funnelId=${funnelId}&startDate=${initDate}&endDate=${endDate}&externalTimezone=${timezone}`)
    const data = await result.json()
    return data.availableDates
  }

  static async getCalendarProfile (calendarId: string): Promise<CalendarProfile> {
    const result = await fetch(`${config.apiUrl}/Inbounds/calendarProfile?calendarId=${calendarId}`)
    const data = await result.json()
    return data.calendarProfile
  }

  static async saveAppointment (appointmentPost: any) {
    const controlledTimeout = 45000 // 45 seconds
    const controller = new AbortController()
    const id = setTimeout(() => controller.abort(), controlledTimeout)

    const result = await fetch(`${config.apiUrl}/Inbounds/schedule`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(appointmentPost),
      signal: controller.signal
    })
    clearTimeout(id)

    const data = await result.json()
    return data
  }

  static async slotSelectedGA (funnelId: string, slot: any) {
    const result = await fetch(`${config.apiUrl}/Inbounds/funnelSlotSelected?funnelId=${funnelId}&slotStart=${slot.start}`)
    const data = await result.json()
  }
}
