
import { Vue, Options } from 'vue-class-component'

@Options({
  props: {
    dark: Boolean
  }
})
export default class IconLoading extends Vue {
  dark!: boolean
  mainColor = '#fff'

  created () {
    if (this.dark) {
      this.mainColor = '#777'
    }
  }
}
