export default [
  'firstName',
  'lastName',
  'phoneNumber',
  'email',
  'dateOfBirth',
  'legalSex',
  'address',
  'zipCode',
  'city',
  'userState',
  'ssn',
  'insuranceCompany',
  'insuranceGroupNumber',
  'insuranceIdNumber',
  'insurancePlanType'
]
