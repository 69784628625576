
import IconArrow from '@/components/icons/IconArrow.vue'
import IconLoading from '@/components/icons/IconLoading.vue'
import ApiService from '@/services/ApiService'
import UtilService from '@/services/UtilService'
import { store } from '@/store'
import { Slot } from '@/types/Slot'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {
    IconArrow,
    IconLoading
  },
  props: {
    location: String,
    userId: Number,
    appointmentType: String,
    provider: String,
    providerIds: Array,
    timezone: String,
    shouldAskProvider: Boolean,
    isAnyProviderSelected: Boolean,
    isOptimizeProvidersOfferingEnabled: Boolean,
    autoSelectDate: Boolean,
    availableDatesOnMemory: Boolean,
    currentStep: Number,
    googleAnalyticsId: String,
    funnelId: String,
    providerName: String,
    useIframe: Boolean,
    stateSelected: String
  },
  watch: {
    combinationKey: {
      handler: 'onSelectionParamsChange'
    },
    provider: {
      handler: 'resetCalendarState'
    },
    appointmentType: {
      handler: 'resetCalendarState'
    },
    loading () {
      this.$emit('updateLoading', this.loading)
    },
    loadingSlots () {
      this.$emit('updateLoadingSlots', this.loadingSlots)
    }
  }
})
export default class Calendar extends Vue {
  location!: string
  userId!: number
  appointmentType!: string
  provider!: string
  providerIds!: Array<string>
  timezone!: string
  shouldAskProvider!: boolean
  isAnyProviderSelected!: boolean
  isOptimizeProvidersOfferingEnabled!: boolean
  autoSelectDate!: boolean
  availableDatesOnMemory!: boolean
  googleAnalyticsId!: string
  funnelId!: string
  providerName!: string
  useIframe!: boolean
  stateSelected!: string

  slotsInMonth: any = {}
  slots: Slot[] = []

  daysPositions: any[] = []

  yearForToday = new Date().getFullYear()
  monthForToday = new Date().getMonth() + 1
  dayForToday = new Date().getDate()

  currentYear = new Date().getFullYear()
  currentMonth = new Date().getMonth() + 1

  getAvailableDates!: () => Promise<string[]>
  getAvailableSlots!: () => Promise<Slot[]>

  theEarliestAvailableDateHasNotBeenDisplayed = true
  // Once, at the beginning
  isSearchingAvailableDates = true

  todayNumber = 0
  selectedDayNumber = 0

  loading = false
  loadingSlots = false

  viewCalendarStepFlag = false

  // To know if the provider in query is being considered.
  isConsideringProviderInQuery = false

  get combinationKey () {
    if (this.isAnyProviderSelected) return `${this.appointmentType}-${this.provider}-${this.currentYear}-${this.currentMonth}-${this.timezone}-${this.providerIds}`
    return `${this.appointmentType}-${this.provider}-${this.currentYear}-${this.currentMonth}-${this.timezone}`
  }

  // Validate if the provider in query should be considered
  get shouldConsiderProviderInQuery () : boolean {
    return this.shouldAskProvider && this.isConsideringProviderInQuery
  }

  resetCalendarState () {
    this.currentYear = new Date().getFullYear()
    this.currentMonth = new Date().getMonth() + 1
    this.theEarliestAvailableDateHasNotBeenDisplayed = true
    this.isSearchingAvailableDates = true
    this.selectedDayNumber = 0
  }

  onSelectionParamsChange () {
    this.loadSlotsInMoth()
  }

  mounted () {
    this.createDaysOfCurrentMonth()
    // If we have provider in query, we start to considering the provider in the query
    this.isConsideringProviderInQuery = Boolean(this.$route.query.dr)
    this.getAvailableDates = this.memorizeAvailableDates()
    this.getAvailableSlots = this.memorizeAvailableSlots()
  }

  createDaysOfCurrentMonth () {
    this.daysPositions = []
    this.todayNumber = 0

    const firstDayOfMonthUTC = new Date(Date.UTC(this.currentYear, this.currentMonth - 1, 1)).getUTCDay()
    const daysInMonth = new Date(this.currentYear, this.currentMonth, 0).getDate()

    for (let i = 0; i < firstDayOfMonthUTC; i++) {
      this.daysPositions.push('')
    }

    for (let i = 1; i <= daysInMonth; i++) {
      this.daysPositions.push(i)
    }

    const today = new Date()
    if (today.getFullYear() === this.currentYear && today.getMonth() === this.currentMonth - 1) {
      this.todayNumber = today.getDate()
    }
  }

  async onClickDayOfMonth (day: number) {
    try {
      if (day > 0 && !this.loadingSlots) {
        this.selectedDayNumber = day
        this.loadingSlots = true

        let slots = await this.getAvailableSlots()

        if (this.isAnyProviderSelected && this.isOptimizeProvidersOfferingEnabled) {
          // Sort the slots by the same order of the providers
          slots = slots.sort((a: { organizer: any, start: any }, b: { organizer: any, start: any }) => {
            // Compare start dates
            if (a.start < b.start) {
              return -1
            }
            else if (a.start > b.start) {
              return 1
            }
            // If start dates are equal, compare organizers
            const aProviderIndex = this.providerIds.indexOf(a.organizer)
            const bProviderIndex = this.providerIds.indexOf(b.organizer)
            if (aProviderIndex < bProviderIndex) {
              return -1
            }
            else if (aProviderIndex > bProviderIndex) {
              return 1
            }
            // If both properties are equal, leave the order unchanged
            return 0
          })
        }

        this.slotsInMonth[day] = slots.map((it: Slot) => new Slot(it))
        this.slotsInMonth[day] = this.slotsInMonth[day].reduce((acc: [Slot], item: Slot) => {
          if (!this.validateStart(acc, item)) {
            acc.push(item)
          }
          return acc
        }, [])
        this.$emit('select', {
          date: new Date(this.currentYear, this.currentMonth - 1, day),
          slots: this.slotsInMonth[day]
        })
        this.loadingSlots = false
      }
    }
    catch (err) {
      this.$emit('select', {
        date: new Date(this.currentYear, this.currentMonth - 1, day),
        slots: []
      })
      this.loadingSlots = false
    }
  }

  validateStart (arraySlots: [Slot], slot : Slot) {
    for (const i in arraySlots) {
      if (arraySlots[i].start === slot.start) {
        return true
      }
    }
    return false
  }

  prevMonth () {
    if (this.loading || this.loadingSlots) return
    if (this.isPreviousDisabled()) return
    if (this.currentMonth > 1) {
      this.currentMonth--
    }
    else {
      this.currentMonth = 12
      this.currentYear--
    }
    this.selectedDayNumber = 0
    this.$emit('hideDaySlots')
    this.createDaysOfCurrentMonth()
  }

  nextMonth () {
    if (this.loading || this.loadingSlots) return
    if (this.currentMonth < 12) {
      this.currentMonth++
    }
    else {
      this.currentMonth = 1
      this.currentYear++
    }
    this.selectedDayNumber = 0
    this.$emit('hideDaySlots')
    this.createDaysOfCurrentMonth()
  }

  async loadSlotsInMoth () {
    // Don't load if there is no appointment type
    if (!this.appointmentType) return
    // If ask for provider and we don't have provider selected or any provider selected, don't load available dates.
    if (this.shouldAskProvider && !this.provider && !this.isAnyProviderSelected) return
    // If is any provider selected but there is no at least 2 providers, don't load available dates.
    if (this.shouldAskProvider && this.isAnyProviderSelected && this.providerIds.length < 2) return
    // If we should consider the provider in the query, but we don't have a provider, that means that we are not considering the provider in query.
    if (this.shouldConsiderProviderInQuery && !this.provider) {
      this.isConsideringProviderInQuery = false
      return
    }
    this.loading = true
    store.commit('changeLoadingSlots', this.loading)

    this.viewCalendarStepFlag = UtilService.updateGAFlag(this.googleAnalyticsId, this.viewCalendarStepFlag, 'view-calendar', this.providerName, this.useIframe)

    const availableDates = await this.getAvailableDates()

    const slotsInMonth: any = {}

    if (this.availableDatesOnMemory) {
      // Check that there is at least one slot
      if (availableDates && Object.keys(availableDates) && Object.keys(availableDates)[0]) {
        const earliestAvailableDate = Object.keys(availableDates)[0]
        const earliestAvailableYear = Number(earliestAvailableDate.substring(0, 4))
        const earliestAvailableMonth = Number(earliestAvailableDate.substring(5, 7))
        // Checks if the closest available month is after the current month
        if (earliestAvailableMonth > this.currentMonth || earliestAvailableYear > this.currentYear) {
          this.isSearchingAvailableDates = false
          this.setEarliestAvailableMonthAsCurrent(earliestAvailableMonth, earliestAvailableYear)
          return
        }
        else {
          for (const days in availableDates) {
            if (availableDates[days]) {
              const dayNumber = parseInt(days.split('-')[2])
              slotsInMonth[dayNumber] = []
            }
          }
        }
        // If there are available dates
        if (Object.keys(slotsInMonth) && Object.keys(slotsInMonth).length > 0) {
          if (this.isSearchingAvailableDates) {
            this.isSearchingAvailableDates = false
          }
        }
        else {
          if (this.isSearchingAvailableDates) {
            this.$emit('noAvailableDates')
            return
          }
        }
        this.createDaysOfCurrentMonth()
        this.slotsInMonth = slotsInMonth
        if (this.selectedDayNumber !== 0 && this.autoSelectDate) {
          this.onClickDayOfMonth(this.selectedDayNumber)
        }
      }
      else {
        this.$emit('noAvailableDates')
      }
    }

    else {
      for (const days in availableDates) {
        if (availableDates[days]) {
          const dayNumber = parseInt(days.split('-')[2])
          slotsInMonth[dayNumber] = []
        }
      }
      this.createDaysOfCurrentMonth()
      this.slotsInMonth = slotsInMonth
      if (this.selectedDayNumber !== 0 && this.autoSelectDate) {
        this.onClickDayOfMonth(this.selectedDayNumber)
      }
    }

    this.loading = false
    store.commit('changeLoadingSlots', this.loading)
  }

  setEarliestAvailableMonthAsCurrent (month: number, year: number) {
  // Show the first month with availability at the beginning (Once)
    if (this.theEarliestAvailableDateHasNotBeenDisplayed) {
      this.theEarliestAvailableDateHasNotBeenDisplayed = false
      this.currentYear = year
      this.currentMonth = month
    }
    else {
      this.slotsInMonth = []
      if (this.selectedDayNumber !== 0 && this.autoSelectDate) {
        this.onClickDayOfMonth(this.selectedDayNumber)
      }
      this.loading = false
      store.commit('changeLoadingSlots', this.loading)
    }
  }

  slotsAvailablesInDay (day: number) {
    return !this.isDayDisabled(day) && !!this.slotsInMonth[day]
  }

  isDayDisabled (dayOfMonth: number) {
    const currentDay = new Date(this.currentYear, this.currentMonth - 1, dayOfMonth)
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    return currentDay.getTime() < today.getTime()
  }

  isPreviousDisabled () {
    const ref = new Date()
    return this.currentYear === ref.getFullYear() && this.currentMonth === ref.getMonth() + 1
  }

  memorizeAvailableDates () : any {
    const memorizeCache: Record<string, Record<string, boolean>[]> = {}

    return async () => {
      const combinationKey = `${this.appointmentType}_${this.provider}_${this.currentYear}_${this.currentMonth}_${this.timezone}`

      if (!memorizeCache[combinationKey]) {
        let availableDates: Record<string, boolean>[]
        if (this.isAnyProviderSelected) {
          availableDates = await ApiService.getAnyProviderAvailableDates(
            this.userId,
            this.location,
            this.appointmentType,
            this.providerIds,
            this.currentYear,
            this.currentMonth,
            this.funnelId,
            this.timezone
          )
        }
        else {
          availableDates = await ApiService.getAvailableDates(
            this.userId,
            this.location,
            this.appointmentType,
            this.provider || '',
            this.currentYear,
            this.currentMonth,
            this.funnelId,
            this.timezone,
            this.stateSelected
          )
        }

        memorizeCache[combinationKey] = availableDates
      }
      return memorizeCache[combinationKey]
    }
  }

  memorizeAvailableSlots () : any {
    const memorizeCache: Record<string, any> = {}
    const SLOTS_MEMORIZATION_EXPIRATION_TIME = 60000 // 1 minute
    const SIMULATED_RESPONSE_TIME = 200 // 200 ms

    return async () => {
      const combinationKey = `${this.appointmentType}_${this.provider}_${this.currentYear}_${this.currentMonth}_${this.selectedDayNumber}_${this.timezone}_${this.stateSelected}`

      if (memorizeCache[combinationKey] && new Date().getTime() - memorizeCache[combinationKey].timeStamp < SLOTS_MEMORIZATION_EXPIRATION_TIME) {
        await UtilService.sleep(SIMULATED_RESPONSE_TIME)
        return memorizeCache[combinationKey].slots
      }

      const slotsResponse = await ApiService.getSlots(
        this.userId,
        this.location,
        this.appointmentType,
        this.provider || '',
        this.funnelId,
        this.timezone,
        this.currentYear,
        this.currentMonth,
        this.selectedDayNumber,
        this.stateSelected
      )
      const [slots] = Object.values(slotsResponse)
      memorizeCache[combinationKey] = {}
      memorizeCache[combinationKey].slots = slots
      memorizeCache[combinationKey].timeStamp = new Date().getTime()

      return memorizeCache[combinationKey].slots
    }
  }
}
