<template>
    <div v-if="optionsList" :class="calculatedClass">
        <label :for="'input-'+name">{{ calculatedLabel + (isMandatory ? ' *' : '')}}</label>
        <div class="select">
          <select :id="'input-'+name" v-model="form[name]" :class="errorClass">
            <option value="">{{ defaultOptionText || $t('wizard.dropdown_default') }}</option>
            <option v-for="(option, key) in optionsList" :key="option.id" :value="key">{{ option }}</option>
        </select>
        </div>
        <p v-if="goingToShowWarningMessage">&#9888; {{warningMessage}}</p>
    </div>
    <!-- Empty space for single line select -->
    <span v-if="isSingleInLine" :class="calculatedClass"/>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default {
  props: {
    // Prop to name input (select)  and his attributes (id, for) and how will add in the geneal form.
    name: {
      type: String,
      required: true
    },
    // The label will shown in the input form.
    label: {
      type: String,
      default: ''
    },
    // Placeholder text, if it is not defined, it will use the label
    defaultOptionText: {
      type: String,
      default: ''
    },
    // Define if the element will be validate
    isMandatory: {
      type: Boolean,
      default: false
    },
    // Warning text, is a text that will be displayed bellow the select with a warning icon.
    warningMessage: {
      type: String,
      default: ''
    },
    // Validation to show the warning message
    validationToShowWarningMessage: {
      type: Boolean,
      default: false
    },
    // Define is have a free space after the input in horizontal
    isSingleInLine: {
      type: Boolean,
      default: false
    },
    // List of options will be shown at his value. Key = value ; Value = optionText/optionLabel
    optionsList: {
      type: Object,
      default () {
        return {}
      }
    },
    // To define size, "full": 100%, "half": 50%
    size: {
      type: String,
      default: 'half'
    }
  },
  computed: {
    calculatedClass () {
      const calculatedClass = {
        'full-size': this.size === 'full',
        'half-size': this.size === 'half'
      }
      return calculatedClass
    },
    calculatedLabel () {
      return this.label || this.labelFromName
    },
    errorClass () {
      const errorClass = {
        error: this.formErrors.includes('input-' + this.name) && this.isMandatory
      }
      return errorClass
    },
    form () {
      return this.$parent.$parent.form
    },
    formErrors () {
      return this.$store.state.formErrors
    },
    goingToShowWarningMessage () {
      return Boolean(this.warningMessage && this.validationToShowWarningMessage)
    },
    labelFromName () {
      // lowerCamelCase to snakeCase
      const nameAsSnakeCase = this.name.replace(/([A-Z])/g, ' $1').replaceAll(' ', '_').toLowerCase()
      return this.$t('wizard.form_field_' + nameAsSnakeCase)
    }
  }
}
</script>

<style lang="scss" scoped>
div {
    margin-bottom: 12px;
    position: relative;
    label {
        display: block;
        margin-bottom: 5px;
        font-weight: bolder;
    }
    .select {
      margin-bottom: 7px;
      select{
        width: 100%;
        min-height: 50px;
        line-height: 48px;
        padding: 0 15px;
        outline: none;
        background-color: #EEEEEE;
        border: 1px solid #cccccc;
        border-radius: 4px;
        box-shadow: 1px 1px 7px -1px #b6b6b6;
        font-size: 1.1rem;
        font-family: inherit;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        &.error {
            border: 2px solid red;
        }
        &::-ms-expand {
            display: none;
        }
    }
    &::after {
        content: '\f078';
        font: normal normal normal 16px/1 FontAwesome;
        color: #404040;
        right: 15px;
        bottom: 18px;
        position: absolute;
        pointer-events: none;
    }
    }
    p {
      font-size: 13px;
      margin: 0;
    }
}

.full-size {
    width: 100%;
}

.half-size {
    width: 48%;
}

// Mobile
@media (max-width: 700px) {
    .full-size, .half-size {
    width: 100%;
    }
}

// Dark Schema
@media (prefers-color-scheme: dark) {
    div {
        select {
            box-shadow: none;
        }
    }
}
</style>
