<template>
  <div class="retryable-error__message" :class="{'retryable-error__message--half-screen': isHalfScreen}">
    <div class="retryable-error__title" :class="{'retryable-error__title--half-screen': isHalfScreen}">
      {{titleText}}
    </div>
    <div v-if="bodyText" class="retryable-error__body">
      {{bodyText}}
    </div>
  </div>
  <div v-if="!withoutButton" class="retryable-error__button button" @click="$emit('buttonClicked')">
    <label>&#10229; &nbsp; {{ buttonText ?? $t('global.try_again')}}</label>
  </div>
</template>

<script lang="ts">

export default {
  props: {
    // Title of the error view.
    titleText: {
      type: String,
      required: true
    },
    // Body of the error view.
    bodyText: {
      type: String,
      default: ''
    },
    // To know that the retryable error doesn't have button.
    withoutButton: {
      type: Boolean,
      default: false
    },
    // Text in the button.
    buttonText: {
      type: String
    },
    // To know if the retryable is half screen.
    isHalfScreen: {
      type: Boolean,
      default: false
    }
  },
  emits: ['buttonClicked']
}
</script>
