import usStates from '@/data/usStates'

export default class CatalogsService {
  static getStatesCatalogAvailable (states: string[]) {
    const US_STATES: any = usStates
    const list = []
    for (const state of Object.keys(US_STATES)) {
      if (states.includes(state)) {
        list.push({ id: state, name: US_STATES[state] })
      }
    }
    return list
  }
}
