export default {
  AM: 'Automobile Medical',
  BL: 'Blue Cross/Blue Shield',
  CH: 'Champus',
  CI: 'Commertial Insurance Co.',
  CO: 'Cash/Other',
  DMO: 'Dental Maintenance Organization',
  DS: 'Disability',
  EB: 'Employer Based or Private',
  EPO: 'Exclusive Provider Organization (EPO)',
  FI: 'Federal Employees Program',
  HM: 'Health Maintenance Organization',
  HMO: 'Health Maintenance Organization (HMO) Medicare Risk',
  II: 'Indemnity Insurance',
  LM: 'Liability Medical',
  MC: 'Medicaid',
  MA: 'Medicare Part A',
  MB: 'Medicare Part B',
  MBV: 'Medicare',
  MD: 'Mutually Defined',
  NS: 'Not sure',
  OF: 'Other Federal Program',
  ONF: 'Other Non-Federal Programs',
  POS: 'Point of Service (POS)',
  PPO: 'Preferred Provider Organization (PPO)',
  TV: 'Title V',
  VA: 'Veterans Affairs Plan',
  WC: "Workers' Compensation Health Claim"
}
