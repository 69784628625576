<template>
  <svg :class="[direction]" viewBox="0 0 16 16" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M8,0c-4.411,0 -8,3.589 -8,8c-0,4.411 3.589,8 8,8c4.411,0 8,-3.589 8,-8c0,-4.411 -3.589,-8 -8,-8Zm0,1.231c3.746,-0 6.769,3.023 6.769,6.769c0,3.746 -3.023,6.769 -6.769,6.769c-3.746,0 -6.769,-3.023 -6.769,-6.769c-0,-3.746 3.023,-6.769 6.769,-6.769Zm-0.291,2.497l-4.101,4.272l4.101,4.272l0.89,-0.851l-2.688,-2.801l6.397,-0.005l-0,-1.23l-6.409,0.004l2.7,-2.81l-0.89,-0.851Z"/></svg>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'

@Options({
  props: {
    direction: String
  }
})
export default class IconArrowCircle extends Vue {
  direction!: string
}
</script>

<style  scoped>
svg.right {
  transform: rotateY(180deg);
}
</style>
