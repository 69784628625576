
import { store } from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
  },
  props: {
    eventType: {
      type: String,
      default: ''
    },
    provider: {
      type: String,
      default: ''
    },
    date: {
      type: Date,
      default: null
    },
    time: {
      type: String,
      default: ''
    },
    timezone: {
      type: String,
      default: ''
    },
    location: {
      type: String,
      default: ''
    },
    isHealthcareSpecialty: {
      type: Boolean,
      required: true
    },
    isVirtualAppointment: {
      type: Boolean,
      default: false
    },
    shouldShowSummaryProviderName: {
      type: Boolean,
      required: true
    },
    currentStep: {
      type: Number,
      required: true
    },
    shouldBeCentered: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    lang () : string {
      return store.state.lang
    },
    formattedDate (): string {
      // If there is no date, return an empty string
      if (!this.date) return ''
      // Else return the date in the format: Tuesday, August 29, 2023
      const formatted = this.date.toLocaleString(this.lang, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
      return formatted.charAt(0).toUpperCase() + formatted.slice(1)
    },
    formattedTimezone (): string {
      // Formatter to get the abbreviation of the timezone
      const formatter = new Intl.DateTimeFormat(this.lang, {
        timeZone: this.timezone,
        timeZoneName: 'short'
      })
      // This abbreviation is the short name of the timezone, for example: GMT-3
      const abbrev = formatter.formatToParts(this.date).find((part) => part.type === 'timeZoneName')?.value || ''
      return `${this.timezone.replaceAll('_', ' ')} (${abbrev})`
    },
    shouldShowEventType (): boolean {
      // The event type should be shown only if we are in the confirm view
      const isOnRequiredSteps = this.currentStep === 6
      return isOnRequiredSteps
    },
    shouldShowProviderData () : boolean {
      return Boolean(this.provider) && this.shouldShowSummaryProviderName
    },
    shouldShowFinalInfo (): boolean {
      // The final info should be shown only if we are in the form or in the confirm view.
      // The date, time, timezone and location are final info
      const isOnRequiredSteps = this.currentStep >= 5
      return isOnRequiredSteps
    },
    shouldShowTimezone (): boolean {
      // The location should be shown only if it is not a virtual appointment, have a location and we are in the required step/view
      return Boolean(this.timezone) && this.shouldShowFinalInfo
    },
    shouldShowLocation (): boolean {
      // The location should be shown only if it is not a virtual appointment, have a location and we are in the required step/view
      return !this.isVirtualAppointment && Boolean(this.location) && this.shouldShowFinalInfo
    }
  }
})
